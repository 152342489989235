import React, { useState, useEffect } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import Permission from '../../components/Permission';
import moment from 'moment';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Box, Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { EmployeeType } from '../../models';

export const FillinDuration = () => {
  const [time, setTime] = useState('--:--:--');

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { from, to, chain, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = { from, to };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    dataProvider.getList('dashboard/fillin-duration', { filter, chainId })
      .then(({ data }) => {
        const { 0: res } = data;
        const formatedTime = res.time
          ? moment('0:0:0', 'H:m:s').add(res.time.toFixed(2) * 60000, 'milliseconds').format('HH:mm:ss') : '--:--:--';
        setTime(formatedTime);
      }).catch(err => console.error(err));
  }, [from, to, chain, stationIds]);

  return (
    <Permission permission={PERMISSIONS.FILLIN_DURATION}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Link to="/your-link">
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& .icon': {
                color: 'secondary.main',
              },
              '&:before': {
                position: 'absolute',
                top: '50%',
                left: 0,
                display: 'block',
                content: `''`,
                height: '200%',
                aspectRatio: '1',
                transform: 'translate(-30%, -60%)',
                borderRadius: '50%',
                backgroundColor: 'secondary.main',
                opacity: 0.15,
              },
            }}
          >
            <Box width="3em" className="icon">
              <AvTimerIcon fontSize="large" />
            </Box>
            <Box textAlign="right">
              <Typography color="textSecondary">Duração de um abastecimento</Typography>
              <Typography variant="h5" component="h2">
                {time}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Card>
    </Permission>
  );
};
