import PrintIcon from '@mui/icons-material/Print';
import { Button } from 'react-admin';
import ReactToPrint from 'react-to-print';

const PrintSummaryButton = (props) => (
  <ReactToPrint
    onBeforeGetContent={props.onClick}
    trigger={() => <Button variant="outlined" sx={{ height: 32, marginBottom: '3px' }} label="Imprimir Totais" startIcon={<PrintIcon />} />}
    content={() => {
      const report = document.querySelector('[class^="ReportPrint-noPaddingWrapper"]');

      const dataGrid = report.cloneNode(true);

      const grids = dataGrid.querySelectorAll('.RaDatagrid-root');
      if (grids.length > 3) {
        grids[3].remove();
      }

      const container = document.createElement("div");
      container.appendChild(dataGrid);

      return container;
    }}
  />
);

export default PrintSummaryButton;