import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  Filter,
  FunctionField,
  Labeled,
  List,
  ListBase,
  ListToolbar,
  NumberField,
  Pagination,
  RaRecord,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceManyField,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  usePermissions,
  useRecordContext
} from "react-admin";
import { AddCoupon, BalanceButton, CheckChainUsesModule, CustomExportButton, CustomizableDatagrid, FillinsDatagrids, ModuleKey, Permission, PrintButton, RemoveCouponButton, RemovePointsButton, ReportPrint, StarRatingField, Wrapper } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import { PartiallyHiddenCpfField, PhoneField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import RefundButton from '../../components/RefundButton';
import { PERMISSIONS } from '../../constants';
import { formatReal, getEmployeeType, getPlaceId, getUserId } from '../../lib';
import { EmployeeType, FillinOrigin, TransactionType } from '../../models';
import { formatPoints } from '../../utils';
import { DrawingDatagrid } from '../drawings/DrawingList';
import { PotencialFraudDataGrid } from '../potencialFraud/PotencialFraudList';
import { RatingDatagrid } from '../ratings/ratingList';

type DataType = Array<{ [key: string]: any }>;
type RelationType = { field: string, resource: string };

const useStyles = makeStyles({
  listMargin: {
    marginTop: 18
  },
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const ClientShowActions = (props) => (
  <TopToolbar>
    <Permission permission={PERMISSIONS.UPDATE_CUSTOMER}>
      <EditButton basePath={props.basePath} record={props.data} />
    </Permission>
  </TopToolbar>
);

// const TransactionRedemptionsList = (props) => {
//   console.log('props: ', props)
//   switch (props.record.type) {
//     case TransactionType.redemption:
//       return (
//         <List>
//         <ReferenceManyField source="coupons" target="coupons" reference="/coupons">
//             <Datagrid rowClick="show">
//                 <TextField source="acronym" label="Sigla" />
//                 <TextField source="name" label="Nome" />
//             </Datagrid>
//         </ReferenceManyField>
//     </SimpleShowLayout>
// </Show>
//       );
//     default:
//       return (
//         <ReferenceManyField
//           reference="transaction-redemptions"
//           target="transactionId"
//           addLabel={false}
//           sort={{ field: "createdAt", order: "DESC" }}
//           pagination={<Pagination />}
//         >
//           <Datagrid expand={<TransactionRedemptionsList />}>
//             <NumberField source="value" label="Valor" />
//             <FunctionField render={record => translateTransactionType(record.type)} label='Tipo' />
//             <NumberField source="balance" label="Saldo Parcial" />
//             <DateField source="createdAt" label="Data" showTime />
//           </Datagrid>
//         </ReferenceManyField>
//       )
//   }
// };

const RefundButtonField = (props) => {
  const coupon = useRecordContext();
  if (!coupon) { return <></>; }
  return (
    coupon ?
      coupon.redeemed ? <FunctionField render={record => "Cupom Utilizado"} />
        : coupon.refunded ? <FunctionField render={record => "Cupom estornado"} />
          :
          <Permission permission={PERMISSIONS.REFUND_COUPON}>
            <RefundButton record={coupon} customerId={props.customerId} />
          </Permission>
      : null
  );
};

const CheckExistenceOfData = ({ children }) => {
  const { data } = useListContext();
  if (!data || data.length === 0) {
    return <></>;
  }

  return (React.cloneElement(children));
};

const FillinList = ({ fillinId }) => {
  return (
    <>
      <Typography style={{ marginTop: 20 }} variant="body1" component="h4" align="center">Abastecimento</Typography>
      <List
        exporter={false}
        filter={{ id: fillinId }}
        resource='fillins'
        pagination={false}
      >
        <FillinDatagrid />
      </List>
    </>);
}

const FillinDatagrid = () => {
  const { data } = useListContext();
  if (!data || !data.length) { return <></>; }

  const fillin = data[0].data;

  return (
    <Datagrid
      data={fillin}
      bulkActionButtons={false}
      empty={<Typography style={{ marginTop: 20 }} variant="body2" component="h4" align="center">Não há abastecimento</Typography>}
    >
      <ReferenceField
        label="Posto"
        emptyText="Posto não identificado"
        source="placeId"
        reference="places"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Frentista"
        emptyText="Não identificado"
        source="attendantId"
        reference="attendants"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="value"
        label="Valor"
        textAlign="center"
        emptyText="R$ 0,000"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="amount"
        label="Litros"
        textAlign="center"
        emptyText="0"
        options={{
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="hoseNumber"
        label="Bico"
        textAlign="center"
        emptyText="Não informado"
      />
      <NumberField
        source="encerrante"
        label="Encerrante"
        textAlign="center"
        emptyText="Não informado"
        options={{
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="pricePerLiter"
        label="Preço com desconto"
        textAlign="center"
        emptyText="R$ 0,000"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3
        }}
      />
      <NumberField
        source="oldPricePerLiter"
        label="Preço normal"
        textAlign="center"
        emptyText="R$ 0,000"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3
        }}
      />
      <FunctionField label="Desconto" textAlign="center" render={record => record && `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} />
      <ReferenceField source="discountId" reference="discounts" label="Código do desconto" textAlign="center" emptyText="--">
        <TextField source="identify" />
      </ReferenceField>
      <ReferenceField label="Combustível" emptyText="Não identificado" source="fuelId" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  );
};

const TransactionPanel = React.memo(({ customerId }: any) => {
  const record = useRecordContext();
  if (!record) { return null; }

  const SalePanel = (props) => {
    return (
      <ArrayField source="salePartnerProducts">
        <Datagrid bulkActionButtons={false}>
          <FunctionField label="Produto" render={record => record && (record.fuel ? record.fuel.name : record.saleProduct ? record.saleProduct.name : 'Não identificado')} textAlign="center" />
          <NumberField label="Volume" source="amount" textAlign="center" />
          <NumberField label="Pontos" source="points" textAlign="center" />
        </Datagrid>
      </ArrayField>
    );
  };

  const ProductField = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }

    if (record.title) {
      return (
        <TextField
          source="title"
          sortable={false}
        />
      )
    }

    return (
      record.isCashback === true ?
        <FunctionField render={() => 'Cashback'} />
        :
        <ReferenceField reference="chains/chainId/products" source="productId" link={false} textAlign="center">
          <TextField source="name" />
        </ReferenceField>
    );
  };

  return (
    <div style={{ paddingBottom: 20 }}>
      {(record.fillin_id && record.type !== TransactionType.marketing) && <FillinList fillinId={record.fillin_id} />}

      {(record.type === TransactionType.adition || record.type === TransactionType.manualAddition) &&
        <SimpleShowLayout>
          <Box display="flex" style={{ gap: 32 }}>
            <Labeled>
              <ReferenceField label="Funcionário" source="employee_id" reference="employees" emptyText="-">
                <TextField source="name" />
              </ReferenceField>
            </Labeled>
            <Labeled>
              <TextField source="coupon_invoice" label="Número do cupom" textAlign="center" emptyText="--" sortable={false} />
            </Labeled>
            <Labeled>
              <TextField source="coupon_serial_number" label="Série do cupom" textAlign="center" emptyText="--" sortable={false} />
            </Labeled>
          </Box>
        </SimpleShowLayout>
      }

      {(record.type === TransactionType.sale || record.type === TransactionType.refuelNSale || (record.type === TransactionType.refuel && !record.fillin_id)) &&
        < Permission permission={PERMISSIONS.LIST_SALES}>
          <ReferenceManyField reference="sales" target="transactionId" emptyText="Nenhum item foi encontrado">
            <CheckExistenceOfData>
              <>
                <Typography style={{ marginTop: 30 }} variant="body1" component="h4" align="center">Vendas</Typography>
                <Datagrid expand={<SalePanel />} bulkActionButtons={false}>
                  <NumberField source="netValue" label="Valor" textAlign="center" emptyText="R$ 0,00" options={{ style: 'currency', currency: 'BRL' }} />
                  <NumberField source="totalValue" label="Total" textAlign="center" emptyText="R$ 0,00" options={{ style: 'currency', currency: 'BRL' }} />
                  <FunctionField label="Status" render={record => record?.status === 'active' ? 'ATIVA' : record?.status === 'canceled' ? 'CANCELADO' : ''} textAlign="center" />
                </Datagrid>
              </>
            </CheckExistenceOfData>
          </ReferenceManyField>
        </Permission>
      }

      <ReferenceManyField label="Coupons" reference="coupons" target="transaction_id" sort={{ field: 'createdAt', order: 'desc' }}>
        <CheckExistenceOfData>
          <>
            <Typography style={{ marginTop: 30 }} variant="body1" component="h4" align="center">Cupons</Typography>
            <Datagrid bulkActionButtons={false}>
              <TextField source="barcode" />
              <ProductField label="Produto" />
              <NumberField source="value" label="Valor" textAlign="center" />
              <DateField source="refundedAt" label="Data do Reembolso" showTime textAlign="center" />
              <RefundButtonField customerId={customerId} />
              <ReferenceField
                source="redemptionEmployeeId"
                reference="employees"
                label="Funcionário"
                textAlign="center"
                link={false}
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </>
        </CheckExistenceOfData>
      </ReferenceManyField>
      {
        record.rule_id &&
        <Labeled label="Motivo da Recompensa" sx={{ marginTop: 4 }} >
          <ReferenceField source="rule_id" reference="chains/chainId/rules" emptyText="Não identificado">
            <TextField source="label" />
          </ReferenceField>
        </Labeled>
      }
      {
        (record.type === TransactionType.marketing && record.fillin_id) &&
        <Permission permission={PERMISSIONS.LIST_RATINGS}>
          <>
            <Typography style={{ marginTop: 10 }} variant="body1" component="h4" align="center">Avaliação</Typography>
            <ReferenceManyField source="fillin_id" reference="ratings" target="fillinId" label="Avaliação">
              <RatingDatagrid showCustomerColumn={false} />
            </ReferenceManyField>
          </>
        </Permission>
      }
    </div >
  );
});

const LimitField = ({ label, source }) => {
  return (
    <ReferenceField
      label={label}
      source={source}
      reference="chains/chainId/point-rule"
      link={(record: any) => {
        let resource: string;

        if (!record.identify) { return null as any; }

        if (record.identify.startsWith('PO')) {
          resource = 'point-limit';
        } else if (record.identify.startsWith('NA')) {
          resource = 'limit-number-fillin';
        } else if (record.identify.startsWith('IN')) {
          resource = 'point-fillin-interval';
        } else {
          return null as any;
        }

        return `/chains/chainId/${resource}/${record.id}/show`;
      }}
      sortable={false}
      emptyText='-'
    >
      <TextField source="identify" />
    </ReferenceField>
  );
}

const StatementDatagrid = ({ customerId, chainId }) => (
  <CustomizableDatagrid
    resource={`chains/${chainId}/customers/transactions`}
    bulkActionButtons={false}
    expand={<TransactionPanel customerId={customerId} />}
    defaultColumns={['created_at', 'value', 'type', 'partial_balance', 'fillin_fuel_id', 'place_id', 'company_id', 'attendant_id', 'expires_at']}
    isRowExpandable={record => record && [TransactionType.adition, TransactionType.manualAddition, TransactionType.refuel, TransactionType.refuelNSale, TransactionType.manualRefuel, TransactionType.marketing, TransactionType.redemption, TransactionType.sale, 
      TransactionType.firstRefuel, TransactionType.firstMonth, TransactionType.firstWeek, TransactionType.birthdayDay, TransactionType.birthdayMonth, TransactionType.completeRegistration, ].includes(record.type)}
  >
    <DateField source="created_at" label="Data" sortable={false} showTime />
    <NumberField source="value" label="Pontos" sortable={false} textAlign="center" />
    <FunctionField source="type" render={record => TransactionType.transalateType(record ? record.type : null)} label="Tipo" textAlign="center" sortable={false} />
    <NumberField source="partial_balance" label="Saldo Parcial" textAlign="center" sortable={false} />
    <ReferenceField label="Produto" reference="fuels" source="fillin_fuel_id" link={false} emptyText="-">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Local" source="place_id" reference="places" link={false} sortable={false} emptyText="-">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Empresa" source="company_id" reference="companies" link={false} sortable={false} emptyText="-">
      <FunctionField render={record => record ? record.name || formatCNPJ(record.cnpj) : ''} />
    </ReferenceField>
    <ReferenceField
      label="Frentista"
      source="attendant_id"
      reference="attendants"
      textAlign="center"
      emptyText="-"
      link={false}
      sortable={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <DateField source="expires_at" label="Expira em" sortable={false} showTime />
    <TextField source="description" label="Descrição" emptyText="-" />
    {
      CheckChainUsesModule({
        module: ModuleKey.LIMITS, children: (
          <LimitField source="limit_id" label="Limite" />
        )
      }) || <></>
    }
    <FunctionField source="system_origin" render={record => FillinOrigin.translateOrigin(record ? record.system_origin : null)} label="Origem" textAlign="center" sortable={false} />
    <RemovePointsButton />
  </CustomizableDatagrid>
);

const StatementsActions = () => {
  const dataProvider = useDataProvider();
  const preExporter = async (
    statements: DataType,
    fetchRelatedRecords: (relations: Array<RelationType>) => Promise<(RelationType & { results: DataType; })[]>
  ) => {
    const relations = [
      { field: 'fillin_fuel_id', resource: 'fuels' },
      { field: 'place_id', resource: 'places' },
      { field: 'company_id', resource: 'companies' },
      { field: 'employee_id', resource: 'employees' },
      { field: 'fillin_attendant_id', resource: 'attendants' },
      { field: 'limit_id', resource: 'point-rule' },
    ];
    const IDS_MAX_LENGTH = 240;
    const filterTransactionsIds = statements.filter((statement) => statement.type !== TransactionType.discount && statement.type !== TransactionType.canceled && statement.type !== TransactionType.refund && statement.type !== TransactionType.expiration).map(({ id }) => id);
    const folds = Math.ceil(filterTransactionsIds.length / IDS_MAX_LENGTH);

    const promises: RaRecord[] = [];
    const salesData: RaRecord[] = [];
    const couponsData: RaRecord[] = [];

    for (let i = 0; i < folds; i++) {
      const start = i * IDS_MAX_LENGTH; //   0, 240, 0, ...
      const end = (i + 1) * IDS_MAX_LENGTH - 1; // 239, 478, ...

      promises.push(
        //@ts-ignore
        dataProvider.getList('sales', { filter: { transactionIds: filterTransactionsIds.slice(start, end) } })
          .then(({ data }) => salesData.push(...data))
      );
      promises.push(
        //@ts-ignore
        dataProvider.getList('coupons', { filter: { transactionIds: filterTransactionsIds.slice(start, end) } })
          .then(({ data }) => couponsData.push(...data))

      );
    }
    await Promise.all(promises);
    const fetchData = await fetchRelatedRecords(relations);

    const getData = (resource: string) => {
      return fetchData.find(({ resource: res }) => res === resource)?.results || [];
    };

    const employeeIds = couponsData.filter((c) => c.redemptionEmployeeId).map((c) => c.redemptionEmployeeId);

    const { data: redemptionEmployee } = await dataProvider.getMany('employees', { ids: employeeIds });

    statements = statements.reduce((acc: Array<{}>, { ...statement }) => {
      const sale = salesData.find((sale) => sale.transactionId === statement.id);
      if (sale?.salePartnerProducts) {
        return [...acc, ...sale.salePartnerProducts.map((s) => {
          const prefixSale = Object.keys((s)).reduce((acc, key) => {
            acc[`sale_${key}`] = s[key];
            return acc;
          }, {});
          prefixSale['sale_totalValue'] = sale?.totalValue;
          prefixSale['sale_netValue'] = sale?.netValue;
          prefixSale['sale_status'] = sale?.status;
          return { ...statement, ...prefixSale };
        })];
      }

      const coupons = couponsData.filter((c) => c.transactionId === statement.id);

      if (coupons.length) {
        return [...acc, ...coupons.map((c) => {
          const prefixCoupon = Object.keys((c)).reduce((acc, key) => {
            acc[`coupon_${key}`] = c[key];
            return acc;
          }, {});
          return { ...statement, ...prefixCoupon };
        })];
      }

      return [...acc, { ...statement }];
    }, []);

    return statements.map((statement) => {
      const { place_id, company_id, fillin_fuel_id, fillin_attendant_id, employee_id, limit_id, id } = statement;
      const fuel = getData('fuels').find(({ id }) => id === fillin_fuel_id);
      const place = getData('places').find(({ id }) => id === place_id);
      const company = getData('comapnies').find(({ id }) => id === company_id);
      const attendant = getData('attendants').find(({ id }) => id === fillin_attendant_id);
      const employee = getData('employees').find(({ id }) => id === employee_id);
      const couponEmployee = redemptionEmployee.find((e) => e.id === statement.coupon_redemptionEmployeeId);
      const pointRule = getData('point-rule').find(({ id }) => id === limit_id);

      return {
        id,
        data: statement.created_at,
        pontos: statement.value,
        tipo: TransactionType.transalateType(statement.type),
        saldo_parcial: statement.partial_balance,
        produto: fuel ? fuel.name : '-',
        local: place ? place.name : '-',
        empresa: company ? company.name || formatCNPJ(company.cnpj) : '-',
        expira_em: statement.expirat_at,
        origem: FillinOrigin.translateOrigin(statement.origin),
        limite: pointRule ? pointRule.identify : '-',
        descricao: statement.description,
        abastecimento_frentista: attendant ? attendant.name : '-',
        abastecimento_valor: statement.fillin_value ? statement.fillin_value : '-',
        abastecimento_litros: statement.fillin_amount ? statement.fillin_amount : '-',
        abastecimento_bico: statement.fillin_hose_number ? statement.fillin_hose_number : '-',
        abastecimento_encerrante: statement.fillin_encerrante ? statement.fillin_encerrante : '-',
        abastecimento_preco_com_desconto: statement.fillin_price_per_liter ? statement.fillin_price_per_lite : '-',
        abastecimento_preco_normal: statement.fillin_old_price_per_liter ? statement.fillin_old_price_per_liter : '-',
        abastecimento_desconto: statement.fillin_discount ? statement.fillin_discount : '-',
        abastecimento_combustivel: fuel ? fuel.name : '-',
        funcionario: employee ? employee.name : '-',
        venda_valor: statement.sale_netValue ? statement.sale_netValue : '-',
        venda_total: statement.sale_totalValue ? statement.sale_totalValue : '-',
        venda_status: statement.sale_status ? statement.sale_status === 'active' ? 'ATIVA' : statement.sale_status === 'canceled' ? 'CANCELADO' : '' : '-',
        venda_volume: statement.sale_amount ? statement.sale_amount : '-',
        venda_pontos: statement.sale_points ? statement.sale_points : '-',
        venda_produto: statement.sale_fuel ? statement.sale_fuel.name : statement.sale_saleProduct ? statement.sale_saleProduct.name : '-',
        cupom_barcode: statement.coupon_barcode ? statement.coupon_barcode : '-',
        cupom_produto: statement.coupon_isCashback ? 'cashback' : statement.coupon_product ? statement.coupon_product.name : '-',
        cupom_valor: statement.coupon_value ? statement.coupon_value : '-',
        cupom_data_do_reembolso: statement.coupon_refundedAt ? statement.coupon_refundedAt : '-',
        cupom_funcionario: couponEmployee ? couponEmployee.name : '-',
        cupom_numero_do_cupom: statement.type === TransactionType.adition ? statement.coupon_invoice || '-' : '-',
        cupom_serie_do_cupom: statement.type === TransactionType.adition ? statement.coupon_serial_number || '-' : '-',
      };
    });

  };

  return (
    <TopToolbar>
      <CustomExportButton fileName='Extrato' preExport={preExporter} isReport={false} />
    </TopToolbar>
  );
};

const ListStatements = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  const CustomersFilters = () => {
    return (
      <Filter>
        <BetweenDatesInput alwaysOn />
        <SelectInput
          label="Tipo"
          source="type"
          choices={TransactionType.transactionTypeChoices}
          alwaysOn
        />
      </Filter>
    )
  }

  return (
    <List
      title=' '
      resource={`chains/${record.chainId}/customers/transactions`}
      sort={{ field: "created_at", order: "DESC" }}
      filters={<CustomersFilters />}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
      }}
      filter={{ customerId: record.id }}
      exporter={false}
      disableSyncWithLocation
      actions={<StatementsActions />}
    >
      <StatementDatagrid customerId={record.id} chainId={record.chainId} />
    </List>
  );
}

const FilterTablePrint = ({ customerName }) => {
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, customerName }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField source="stationIds" textAlign="center" reference="stations" >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Postos" source="name" />
        </Datagrid>
      </ReferenceArrayField>
      <ReferenceArrayField source="fuelIds" textAlign="center" reference="fuels">
        <Datagrid bulkActionButtons={false}>
          <TextField label="Produtos" source="name" />
        </Datagrid>
      </ReferenceArrayField>
      <TextField label="Cliente" source="customerName" textAlign="center" />
    </Datagrid>
  );
};

const ListFillins = ({ ...props }) => {
  const classes = useStyles();
  const fillinsRef = useRef();
  const record = useRecordContext();
  if (!record) { return null; }

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="stationIds"
      reference="stations"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      className={classes.listMargin}
      alwaysOn
    >
      <SelectArrayInput
        label="Postos"
        style={{ minWidth: 180 }}
        optionText={"name"}
      />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      sort={{ field: "name", order: "ASC" }}
      className={classes.listMargin}
      alwaysOn
    >
      <SelectArrayInput
        label="Produtos"
        style={{ minWidth: 180 }}
        optionText={"name"}
      />
    </ReferenceArrayInput>
  ];

  const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations');
  const placeId = getPlaceId();

  if (placeId && stationsIndex > -1) {
    listFilters.splice(stationsIndex, 1);
  }

  const ListFillinsActions = () => (
    <TopToolbar>
      <PrintButton
        printRef={fillinsRef}
      />
    </TopToolbar>
  );

  const filterValues: any = { customerId: record.id };
  if (getEmployeeType() === EmployeeType.promoter) {
    filterValues.promoterId = getUserId();
  }

  return (
    <ListBase
      resource="fillins"
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
        stationIds: placeId ? [placeId] : null
      }}
      filter={filterValues}
      disableSyncWithLocation
    >
      <ListToolbar
        filters={listFilters}
        actions={<ListFillinsActions />}
      />
      <ReportPrint ref={fillinsRef} title="Abastecimentos" landscape>
        <div className={classes.filterTablePrint}>
          <Typography className={classes.titleCard}>Filtros</Typography>
          <FilterTablePrint customerName={record && record.name} />
        </div>

        <FillinsDatagrids {...props} />
        <Pagination />
      </ReportPrint>
    </ListBase>
  );
};

const DrawingPanel = ({ customerId, ...props }) => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource="drawing-lucky-numbers"
      sort={{ field: 'number', order: 'ASC' }}
      filter={{ customerId, drawingId: record.id }}
      perPage={25}
      pagination={<Pagination />}
      actions={false}
      empty={
        <Box style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Typography variant="subtitle1" gutterBottom align="center">Usuário não possui números</Typography>
        </Box>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="number" label="Número" />
      </Datagrid>
    </List>
  );
}

const DrawingList = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource={`chains/${record.chainId}/drawings`}
      filter={{ drawingCustomerId: record.id }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      pagination={<Pagination />}
      actions={false}
      disableSyncWithLocation
    >
      <DrawingDatagrid bulkActionButtons={false} expand={<DrawingPanel customerId={record.id} />} disableEditButton disableDeleteButton />
    </List>
  );
};

const CouponsList = () => {
  const classes = useStyles();
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource="coupons"
      filter={{ customerId: record.id }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      pagination={<Pagination />}
      actions={false}
      disableSyncWithLocation
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField label="Produto" textAlign="center" render={record => record?.isCashback ? "Cashback" : record?.title || record?.product?.name || 'Não identificado'} />
        <TextField source="barcode" label="Cupom" textAlign="center" />
        <NumberField source="value" label="Pontos" textAlign="center" emptyText='-' />
        <NumberField source="partnerValue" label="Valor" textAlign="center" emptyText="R$ 0,00" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }} sortable={false} />
        <DateField source="redeemedAt" label="Data de troca" emptyText="-" textAlign="center" showTime />
        <DateField source="createdAt" label="Data de geração" emptyText="-" textAlign="center" showTime />
        <TextField source="place.name" label="Local de Retirada" sortable={false} />
        <BooleanField source="isGift" label="Brinde" />
        <RemoveCouponButton />
      </Datagrid>
    </List>
  );
};

const DevicesList = (props) => (
  <ReferenceManyField
    reference="chains/chainId/devices"
    addLabel={false}
    target="customerId"
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    pagination={<Pagination />}
  >
    <Datagrid {...props}>
      <TextField source="brand" label="Marca" />
      <TextField source="model" label="Modelo" />
      <SelectField
        source="state"
        label="Status"
        choices={[{ id: "active", name: "ativo" }, { id: "inactive", name: "inativo" }]}
      />
      <DateField source="createdAt" label="Data" showTime />
    </Datagrid>
  </ReferenceManyField>
)

const FraudLogList = (props) => {
  const record = useRecordContext();

  const PotencialFraudFilter = props => (
    <Filter {...props}>
      <SelectInput
        emptyText="Todos"
        source="isOpen"
        alwaysOn
        label='Situação'
        choices={[
          { id: true, name: 'Em aberto' },
          { id: false, name: 'Fechadas' }
        ]}
      />
    </Filter>
  );

  return (
    <List
      {...props}
      perPage={25}
      resource={`chains/${record.chainId}/potencial-frauds`}
      basePath={`chains/${record.chainId}/potencial-frauds`}
      pagination={<ListPagination />}
      exporter={false}
      title=" "
      filters={<PotencialFraudFilter />}
      filter={{ customerId: record.id }}
      filterDefaultValues={{ isOpen: true }}
      disableSyncWithLocation
    >
      <PotencialFraudDataGrid hideName />
    </List>
  );
}

const ReferedList = () => {
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <List
      title=" "
      resource="customers"
      filter={{ refereeCustomerId: record.id }}
      sort={{ field: "referedAt", order: "DESC" }}
      exporter={false}
      pagination={<ListPagination />}
      perPage={25}
      disableSyncWithLocation
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Cliente Indicado" />
        <DateField source="referedAt" label="Data da Indicação" emptyText="-" textAlign="center" />
        <ReferenceField reference="customer-first-fillin" source="id" label="Data do Abastecimento" sortable={false} emptyText="-" textAlign="center" >
          <DateField source="createdAt" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}


export default ({ ...props }) => {
  const employeeType = getEmployeeType();
  const { permissions } = usePermissions();
  const [displayCouponModal, setDisplayCouponModal] = useState(false);
  const [displayBalanceModal, setDisplayBalanceModal] = useState(false);

  const ClientShowActions = (props) => (
    <TopToolbar>
      <Permission permission={PERMISSIONS.ADD_POINTS}>
        <Button
          variant="outlined"
          label="Adicionar Pontos"
          onClick={() => setDisplayBalanceModal(true)}
        />
      </Permission>
      <Permission permission={PERMISSIONS.CREATE_COUPON}>
        <Button
          variant="outlined"
          label="Adicionar Cupom"
          onClick={() => setDisplayCouponModal(true)}
        />
      </Permission>
      <Permission permission={PERMISSIONS.UPDATE_CUSTOMER}>
        <EditButton record={props.data} />
      </Permission>
    </TopToolbar>
  );

  const WrapperBlocked = () => {
    const record = useRecordContext();
    if (!record?.isBlocked) { return null; }
    return (
      <Labeled>
        <ReferenceField label="Bloqueado por" source="blockedByEmployeeId" reference="employees" emptyText="Não identificado" link={(record, reference) => {
          if (permissions && permissions.includes(PERMISSIONS.UPDATE_USER)) {
            return `/${reference}/${record.id}`
          }
          return '';
        }}>
          <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
        </ReferenceField>
      </Labeled>
    )
  }

  return (
    <Show actions={<ClientShowActions />} title="Cliente" {...props} >
      {displayBalanceModal && <Permission permission={PERMISSIONS.ADD_POINTS}>
        <BalanceButton onClose={() => setDisplayBalanceModal(false)} />
      </Permission>
      }
      {displayCouponModal && <Permission permission={PERMISSIONS.CREATE_COUPON}>
        <AddCoupon onClose={() => setDisplayCouponModal(false)} />
      </Permission>
      }
      <TabbedShowLayout>
        <Tab label="Resumo" >
          <FunctionField
            render={record => record?.isBlocked && <Chip label="Bloqueado" color="error" />}
            addLabel={false}
          />
          <TextField source="id" label="ID" />
          <TextField source="name" label="Nome" />
          <PartiallyHiddenCpfField source="cpf" label="CPF" />
          <PhoneField source="cellphone" label="Telefone" />
          <EmailField source="email" />
          <ReferenceField label="Grupo" source="groupId" textAlign="center" emptyText="Sem grupo" reference="chains/chainId/customer-groups" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <ReferenceField label="Rede" source="chainId" textAlign="center" reference="chains" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          <DateField source="createdAt" label="Criado em" textAlign="center" showTime />
          {
            (employeeType === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(employeeType)) &&
            <ReferenceField
              label="Pontos"
              source="id"
              reference="customer-balance"
              emptyText="0"
              link={false}
            >
              <FunctionField
                source="balance"
                render={record => formatPoints(+record?.balance)}
              />
            </ReferenceField>
          }
          <FunctionField label="Status" render={(record) => record.isBlocked ? 'Bloqueado' : 'Ativo'} />
          <WrapperBlocked />
        </Tab>

        {(permissions && permissions.includes(PERMISSIONS.CUSTOMER_STATEMENT)) &&
          <Tab label="Extrato" path="transactions" hidden>
            <ListStatements />
          </Tab>
        }
        {/* <Tab label="Extrato" path="statement">
        <ReferenceManyField
          reference="customers/customerId/transactions"
          target="customerId"
          addLabel={false}
          sort={{ field: "createdAt", order: "DESC" }}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false}>
            <NumberField source="value" label="Valor" />
            <FunctionField render={record => translateTransactionType(record.type)} label='Tipo' />
            <NumberField source="balance" label="Saldo Parcial" />
            <DateField source="createdAt" label="Data" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab> */}
        <Tab label="Abastecimentos" path="fillins">
          <ListFillins {...props} />
        </Tab>
        <Tab label="Avaliações" path="ratings">
          <ReferenceManyField
            reference="ratings"
            addLabel={false}
            target="customerId"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            pagination={<ListPagination />}
          >
            <Datagrid {...props} bulkActionButtons={false}>
              <ReferenceField source="customerId" label="Usuário" reference="customers" link='show'>
                <TextField source="name" />
              </ReferenceField>
              <StarRatingField source="attendantRating" label="Avaliação do frentista" />
              <StarRatingField source="stationRating" label="Avaliação do Posto" />
              <TextField source="comment" label="Comentário" />
              <DateField source='createdAt' label='Data' showTime />
              <ReferenceField label="Abastecimento" source="fillinId" reference="fillins" link='show' sortable={false}>
                <FunctionField render={() => 'Abastecimento'} />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {
          (permissions && permissions.includes(PERMISSIONS.COUPONS_CODE)) &&
          <Tab label="Cupons" path="coupons" >
            <CouponsList />
          </Tab>
        }
        {
          (permissions && permissions.includes(PERMISSIONS.DRAWING)) &&
          <Tab label="Sorteios" path="drawings">
            <DrawingList />
          </Tab>
        }
        {
          (permissions && permissions.includes(PERMISSIONS.DEVICE)) &&
          <Tab label="Dispositivos" path="devices">
            <DevicesList />
          </Tab>
        }
        {
          (permissions && permissions.includes(PERMISSIONS.POTENCIAL_FRAUD_LIST)) &&
          <Tab label="Segurança" path="frauds">
            <FraudLogList />
          </Tab>
        }
        <Tab path="refered" label="Indicações">
          <ReferedList />
        </Tab>

      </TabbedShowLayout>
    </Show>
  );
}
