import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useCallback, useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardWrapper } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import { EmployeeType } from '../../models';
import { formatNumber, getInitialChart } from '../../utils';
moment.locale('pt-br');

const colors = ['#5AD8A6', '#5B8FF9', '#F6BD16', '#FF6F61'];
const initialData = { totalValue: 0, totalFillins: 0, totalAmount: 0, discount: 0 };

// Custom styling for Radio Buttons
const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    '& .MuiFormControlLabel-root': {
        flex: '1',
        margin: 0,
        borderRadius: '20px',
        border: '1px solid #ddd',
        transition: 'all 0.3s ease',
        textAlign: 'center',
        padding: '5px 16px',
        backgroundColor: '#f9f9f9',
        '&:hover': {
            backgroundColor: '#f0f0f0',
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-checked': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            borderColor: theme.palette.primary.main,
            fontWeight: 'bold',
        },
    },
    '& .MuiRadio-root': {
        display: 'none',
    },
}));

export const FillinsChart = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, initialData);

    const [chartColor, setChartColor] = useState(colors[0]);
    const [chartData, setChartData] = useState(initialChart);
    const [chartType, setChartType] = useState('totalValue');

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        const filter = { from, to, chainId, origin };
        if (stationIds && stationIds.length > 0) {
            filter.stationIds = stationIds;
        }
        if (getEmployeeType() === EmployeeType.attendant) {
            filter.attendantId = getAttendantId();
        }

        dataProvider
            .getList('dashboard/fillins-summary', { filter })
            .then(({ data }) => setChartData(data));
    }, [from, to, chain, origin, stationIds]);


    const formatter = useCallback((value) => {
        switch (chartType) {
            case 'totalAmount':
                return formatNumber(value, { style: 'decimal', unit: 'liter' });
            case 'totalFillins':
                return `${formatNumber(value)} Abastecimentos`;
            default:
                return formatNumber(value, { style: 'currency', currency: 'BRL' });
        }
    }, [chartType]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.FILLINS_CHART}
            filters={
                <FormControl style={{ marginBottom: '20px' }}>
                    <StyledRadioGroup
                        row
                        defaultValue="totalValue"
                        onChange={({ target: { value } }) => {
                            setChartType(value);
                            setChartColor(colors[['totalValue', 'totalFillins', 'totalAmount', 'discount'].indexOf(value)]);
                            setChartData([...chartData]); // Refresh for animation
                        }}
                    >
                        {['Dinheiro', 'Abastecimentos', 'Volume', 'Descontos'].map((label, index) => (
                            <FormControlLabel
                                key={index}
                                value={['totalValue', 'totalFillins', 'totalAmount', 'discount'][index]}
                                label={label}
                                control={<Radio />}
                                className={chartType === ['totalValue', 'totalFillins', 'totalAmount', 'discount'][index] ? 'Mui-checked' : ''}
                            />
                        ))}
                    </StyledRadioGroup>
                </FormControl>
            }
            aspectRatio={6.0 / 2.5}
            content={
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={chartData} margin={{ top: 10, right: 24, left: 8, bottom: 0 }}>
                        <YAxis
                            domain={[0, 'auto']}
                            type="number"
                            tick={{ fontSize: 12, fill: '#555' }}
                            axisLine={{ stroke: '#ddd' }}
                            tickLine={{ stroke: '#ddd' }}
                            tickFormatter={(value) => formatNumber(value)}
                        />
                        <XAxis
                            dataKey="date"
                            tick={{ fontSize: 12, fill: '#555' }}
                            axisLine={{ stroke: '#ddd' }}
                            tickLine={{ stroke: '#ddd' }}
                            height={60}
                            tickFormatter={(value) => moment(value, 'DD/MM/YYYY').format('DD MMM')}
                            angle={45}
                            textAnchor="start"
                        />
                        <Tooltip
                            content={({ payload, label }) => {
                                if (!payload || payload.length === 0) return null;
                                return (
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            padding: '10px',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold', color: '#333' }}>
                                            {moment(label, 'DD/MM/YYYY').format('dddd, DD MMM YYYY')}
                                        </p>
                                        {payload.map((entry, index) => (
                                            <p key={index} style={{ margin: '5px 0', color: entry.color }}>
                                                {entry.name}: <strong>{formatter(entry.value)}</strong>
                                            </p>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <defs>
                            {colors.map((color, i) => (
                                <linearGradient key={i} id={color} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={color} stopOpacity={0.75} />
                                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                                </linearGradient>
                            ))}
                        </defs>
                        <Area
                            stroke={chartColor}
                            fillOpacity={0.2}
                            fill={`url(#${chartColor})`}
                            dataKey={chartType}
                            name="Total"
                            type="monotone"
                            animationDuration={800}
                            animationEasing="ease-in-out"
                            strokeWidth={3}
                            activeDot={{ r: 6, stroke: chartColor, strokeWidth: 2 }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            }
        />
    );
};
