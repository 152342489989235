import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';
import { formatNumber, getDatesInRange, getInitialChart } from '../../utils';

const initialData = { generatedpoints: 0, redeemedpoints: 0 };

export const GeneratedXRedeemedGraph = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const { from, to, chain, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, initialData);
    const [chartData, setChartData] = useState(initialChart);

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
        };
        if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

        const data = [];
        getDatesInRange(from, to).map(date =>
            data.push(
                { id: date, generatedpoints: 0, redeemedpoints: 0 }
            )
        );

        dataProvider.getList('dashboard/generated-points', { filter, chainId })
            .then(response => {
                const datesWithdata = response.data;

                datesWithdata.forEach(date => {
                    const currDate = data.find(chartDate => chartDate.id === date.id);
                    if (currDate && (date.generatedpoints > 0 || date.redeemedpoints > 0)) {
                        currDate.generatedpoints = date.generatedpoints;
                        currDate.redeemedpoints = date.redeemedpoints;
                    }
                });
                setChartData(data);
            })
            .catch((e) => { notify('Erro ao carregar gráfico de pontos gerados X pontos resgatados', { type: 'warning' }); });
    }, [from, to, chain, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.GENERATED_REDEEMED_POINTS}
            width='45%'
            title="Pontos Gerados X Pontos Resgatados"
            content={
                <LineChart data={chartData} margin={{ top: 10, right: 16, bottom: 10, left: 16 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis
                        dataKey="id"
                        tick={<RotatedAxisTick fontSize={12} />}
                        height={60}
                        tickFormatter={(tick) => moment(tick).format('DD/MM')}
                    />
                    <YAxis
                        domain={[0, 'auto']}
                        type='number'
                        tickFormatter={(value) => formatNumber(value)}
                        width={50}
                    />
                    <Tooltip
                        formatter={(value, name, props) => `${formatNumber(value)} pontos`}
                        // labelFormatter={(label) => label}
                        itemStyle={{ color: '#333', fontSize: '14px' }}
                        contentStyle={{
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            padding: '8px 16px',
                        }}
                    />
                    <Legend
                        height={50}
                        layout="horizontal"
                        verticalAlign="top"
                        wrapperStyle={{ top: -10, left: 20 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="generatedpoints"
                        stroke="#5AD8A6"
                        strokeWidth={3}
                        activeDot={{ r: 6 }}
                        name="Pontos Gerados"
                        dot={{ r: 4 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="redeemedpoints"
                        stroke="#8884d8"
                        strokeWidth={3}
                        activeDot={{ r: 6 }}
                        name="Pontos Resgatados"
                        dot={{ r: 4 }}
                    />
                </LineChart>
            }
        />
    );
};
