import { useEffect, useState } from 'react';
import {
  Edit,
  FormDataConsumer,
  Labeled,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRefresh
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { Stack } from '@mui/material';
import { RequiredChain, Tooltip } from '../../components';

const FuelInput = ({ chainId, fuelId, label, minimumAmountRule, index }) => {
  const existingFuelIndex = minimumAmountRule.findIndex(item => item.fuelId === fuelId)
  const fuelIndex = existingFuelIndex === -1 ? index : existingFuelIndex;
  if (existingFuelIndex === -1) {
    minimumAmountRule[fuelIndex] = { fuelId };
  }
  return (
    <Labeled label={label}>
      <Stack direction="row" spacing={2} alignItems="baseline">
        <TextInput
          source={`minimumAmountRule[${fuelIndex}].amount`}
          label="Litros (Mínimo)"
          format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
          parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
        />
        <Tooltip title="Esse valor será a quantidade de pontos por litro que o cliente ganhará, se abastecer a quantidade mínima.
          Ex: se colocar esse campo com o valor '1' significa que o cliente ganhará mais 1 ponto por litro no abastecimento que 
          tiver uma quantidade maior que a mínima além do que já ganhou na pontuação padrão ou do grupo"
          sxContainer={{ alignItems: 'flex-start' }} placement="top-start">
          <TextInput
            source={`minimumAmountRule[${fuelIndex}].factor`}
            label="Fator"
            format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
            parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
          />
        </Tooltip>
      </Stack>
    </Labeled>
  );
};

const FuelRules = ({ minimumAmountRule, chainId }) => {
  const [fuels, setFuels] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    setLoading(true);
    dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: false }, filter: { chainId } })
      .then(({ data }) => {
        setFuels(data);
      }).finally(() => setLoading(false));
  }, [chainId]);

  if (loading) { return null; }

  return (
    fuels.map(({ id, name }, index) => (
      <FuelInput minimumAmountRule={minimumAmountRule} chainId={chainId} fuelId={id} label={name} index={index} />
    ))
  );
}

const MininumAmountRule = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };

  const onError = (error) => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/minimum-amount-rule-edit');
  };

  const transform = (data) => {
    return { minimumAmountRule: data.minimumAmountRule.map((item) => ({ ...item, chainId: data.id })) }
  }

  const ToolbarCustom = () => {
    return (
      <Toolbar>
        <SaveButton />
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.MINIMUM_AMOUNT_RULE}>
      <RequiredChain>
        <Edit id={getChainId()} title="Quantidade Mínima" resource="minimum-amount-rule" mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }} transform={transform}>
          <SimpleForm toolbar={<ToolbarCustom />}>
            <FormDataConsumer>
              {({ formData: { id, minimumAmountRule } }) => {
                return (
                  id && (<FuelRules minimumAmountRule={minimumAmountRule} chainId={id} />)
                )
              }
              }
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </RequiredChain>
    </Permission>
  );
};

export default MininumAmountRule;