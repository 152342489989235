import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';
import { getDatesInRange } from '../../utils';

export const AditionPointsChart = () => {
  const { permissions } = usePermissions();
  const [chartData, setChartData] = useState(null);
  const dataProvider = useDataProvider();
  const { from, to, chain, stationIds } = useDashboardContext();
  const notify = useNotify();
  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      from: moment.utc(from),
      to: moment.utc(to),
    };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

    const data = [];
    getDatesInRange(from, to).map(date =>
      data.push(
        { id: date, aditionPoints: 0 }
      ));

    if (permissions && permissions.includes(PERMISSIONS.ADITION_POINTS)) {
      dataProvider.getList('dashboard/adition-points', { chainId, filter })
        .then(response => {
          const datesWithPoints = response.data;

          datesWithPoints.forEach(date => {
            const currDate = data.find(chartDate => chartDate.id === date.date);
            if (currDate) {
              currDate.aditionPoints = date.aditionpoints;
            }
          });
          setChartData(data);
        }).catch((e) => { notify('Erro ao carregar pontos gerados manualmente', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, stationIds]);

  return (
    <DashboardWrapper
      permission={PERMISSIONS.ADITION_POINTS}
      title="Pontos Adicionados Manualmente"
      width="45%"
      link="/report-added-points"
      content={
        <BarChart data={chartData} margin={{ top: 10, right: 16, bottom: 10, left: 16 }}>
          <XAxis 
            dataKey="id" 
            tick={<RotatedAxisTick fontSize={12} />} 
            height={60} 
            tickFormatter={(tick) => moment(tick).format('DD/MM')} 
          />
          <YAxis 
            width={50} 
            domain={['auto', 'auto']} 
            type="number" 
            tickFormatter={(value) => `${value} pontos`} 
          />
          <Tooltip 
            formatter={(value) => `${value} pontos`}
            labelFormatter={(label) => label}
            itemStyle={{ color: '#333', fontSize: '14px' }}
            contentStyle={{
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '5px',
              padding: '8px 16px',
            }}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#DFE2E6" />
          <Legend 
            layout="horizontal" 
            verticalAlign="top" 
            wrapperStyle={{ top: -10, left: 20 }} 
            formatter={(value) => `Pontos Adicionados`}
          />
          <Bar 
            dataKey="aditionPoints" 
            fill="url(#gradient)" 
            barSize={50} 
            name="Pontos Adicionados Manualmente" 
            radius={[5, 5, 0, 0]} 
          />
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#82ca9d" stopOpacity={1} />
              <stop offset="100%" stopColor="#61dafb" stopOpacity={1} />
            </linearGradient>
          </defs>
        </BarChart>
      }
    />
  );
};
