import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
	Datagrid,
	DateField,
	NumberField,
	ReferenceField,
	SelectInput,
	TextField,
	useListContext,
} from 'react-admin';
import { RequiredChain } from '../../components';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const fuelStatus = [
	{ id: true, name: 'Realizou primeiro abastecimento' },
	{ id: false, name: 'Não realizou abastecimento' },
];

const NewCustomerList = () => {
	const classes = useStyles();
	const props = useListContext();

	let data = [];
	let summaryData = [];

	if (props.data) {
		const reportInfo = props.data[0];

		if (reportInfo) {
			data = reportInfo.data;
			summaryData = reportInfo.summaryData;
		}
	}
	console.log("🚀 ~ NewCustomerList ~ summaryData:", summaryData)

	return (
		<>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
				<ReferenceField source="promoter_id" reference="promoters" basePath="stations" label="Promoter">
					<TextField
						source="name"
						label="Promoter"
						textAlign="center"
						emptyText="0"
						sortable={false}
					/>
				</ReferenceField>
				<NumberField
					source="count"
					label="Total"
					textAlign="center"
					emptyText="0"
					sortable={false}
				/>
			</Datagrid>

			<Typography className={classes.title}>Novos clientes</Typography>
			<Datagrid
				bulkActionButtons={false}
				data={data}
				rowClick={false}
			>
				<ReferenceField
					label="Nome"
					emptyText="Cliente sem nome"
					source="id"
					basePath="customers"
					reference="customers"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<PartiallyHiddenCpfField source="cpf" label="CPF" />
				<DateField source="createdAt" label="Data" showTime />
				<ReferenceField source="placeId" reference="places" basePath="stations" label="Local de abastecimento" emptyText="Não abasteceu" sortable={false}>
					<TextField source="name" textAlign="center" />
				</ReferenceField>
			</Datagrid>
		</>
	);
}

const listFilters = [
	<BetweenDatesTimesInput alwaysOn />,
	<SelectInput
		label="Status"
		source="hasFillin"
		emptyText="Todos"
		alwaysOn
		choices={fuelStatus}
	/>
];

const customersExporter = async (data, fetchRelatedRecords) => {
	const relations = [
		{ field: 'placeId', resource: 'stations' },
	];

	const fetchData = await fetchRelatedRecords(relations, data);
	const getData = (resource) => {
		return fetchData.find(({ resource: res }) => res === resource)?.results || [];
	}

	return data.map(customer => (
		{
			id: customer.id,
			name: customer.name,
			cpf: formatCPF(customer.cpf),
			createdAt: customer.createdAt,
			station: getData('stations').find(({ id }) => id === customer.placeId)?.name || '',
		}
	));
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
		</Datagrid>
	);
}

const ReportNewCustomersByPromoters = (props) => {

	return (
		<RequiredChain>
			<Permission permission={PERMISSIONS.NEW_CUSTOMERS_BY_PROMOTERS_REPORT}>
				<ReportList
					{...props}
					basePath={'customers'}
					title="Novos clientes"
					resource="chains/chainId/reports/new-customers-by-promoters"
					filters={listFilters}
					sort={{ field: 'createdAt', order: 'DESC' }}
					perPage={25}
					filterDefaultValues={{ from: moment().startOf('day').toISOString(), to: moment().endOf('day').toISOString() }}
					pagination={<ListPagination />}
					bulkActionButtons={false}
					customExporter={customersExporter}
					titleOnPrint="Relatório de novos clientes por Promoter"
					filterTablePrint={<FilterTablePrint />}
					landscape
					isReport={false}
				>
					<NewCustomerList />
				</ReportList>
			</Permission >
		</RequiredChain>
	);
};

export default ReportNewCustomersByPromoters;