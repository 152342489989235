import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { Tooltip, PieChart, Pie, Cell, Legend } from "recharts";
import { formatReal, getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper } from '../../components';
import moment from 'moment';
import { EmployeeType } from '../../models';

export const FuelChart = () => {
    const [chartData, setChartData] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);

    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const { from, to, chain, origin, stationIds } = useDashboardContext();
    const notify = useNotify();

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    const COLORS = [
        { start: '#484A7F', end: '#6A6ECD' },
        { start: '#EF6937', end: '#FF9A60' },
        { start: '#299B22', end: '#67CE62' },
        { start: '#909090', end: '#B5B5B5' },
        { start: '#EFB810', end: '#FFD34A' }
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#333"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                fontSize="12px"
                fontWeight="bold"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
            origin,
        };
        if (stationIds && stationIds.length > 0) {
            filter.stationIds = stationIds;
        }
        if (getEmployeeType() === EmployeeType.attendant) {
            filter.attendantId = getAttendantId();
        }

        let fuelData = [];
        if (permissions && permissions.includes(PERMISSIONS.FUEL_CHART)) {
            dataProvider.getList('dashboard/fillin-by-fuel', {
                filter,
                chainId,
                sort: { field: 'totalValue', order: 'DESC' },
                pagination: { page: 1, perPage: 5 },
            })
                .then(response => {
                    const data = response.data;
                    Object.keys(data).forEach((fuel) => {
                        if (data[fuel].totalValue === 0 || data[fuel].id === '0') {
                            delete data[fuel];
                        } else if (fuelData.length < 5) {
                            fuelData = [...fuelData, { name: data[fuel].name, value: data[fuel].totalValue }];
                        }
                        setChartData(fuelData);
                    });
                })
                .catch(() => { notify('Erro ao carregar gráfico de combustíveis', { type: 'warning' }); });
        }
    }, [permissions, from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.FUEL_CHART}
            width="100%"
            link="/report-fillins"
            title="Venda de Combustíveis"
            aspectRatio={5.5 / 2.5}
            content={
                <PieChart onMouseLeave={() => setShowTooltip(false)}>
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        iconType="circle"
                        wrapperStyle={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#333',
                            paddingBottom: '10px',
                        }}
                    />
                    <Pie
                        onMouseEnter={() => setShowTooltip(true)}
                        dataKey="value"
                        startAngle={360}
                        endAngle={0}
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        outerRadius="85%"
                        label={renderCustomizedLabel}
                        labelLine={false}
                        animationDuration={800}
                        animationEasing="ease-out"
                    >
                        {chartData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={`url(#gradient${index})`}
                                stroke="#fff"
                                strokeWidth={2}
                            />
                        ))}
                    </Pie>
                    <defs>
                        {COLORS.map((gradient, index) => (
                            <linearGradient key={index} id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={gradient.start} />
                                <stop offset="100%" stopColor={gradient.end} />
                            </linearGradient>
                        ))}
                    </defs>
                    {showTooltip && (
                        <Tooltip
                            content={({ payload }) =>
                                payload.length > 0 ? (
                                    <div
                                        style={{
                                            backgroundColor: '#fff',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            padding: '10px',
                                            fontSize: '14px',
                                            color: '#333',
                                        }}
                                    >
                                        {`${payload[0].name}: ${formatReal(payload[0].value)}`}
                                    </div>
                                ) : null
                            }
                            wrapperStyle={{ visibility: 'visible', pointerEvents: 'auto' }}
                        />
                    )}
                </PieChart>
            }
        />
    );
};
