import {
  ADVERTISEMENTS,
  ADVERTISEMENTS_MAX_PAYLOAD_SIZE,
  DEFAULT_API_ADMIN,
  NOTIFICATION,
  PRODUCT,
  SMS,
  chainIdDelete,
  clearMaskRoutes,
  mapPath,
  routesWithUpload,
} from '../../constants'
import {
  addChainId,
  clearMask,
  getChainId,
  getUserId,
  httpClientReactAdmin,
  makePayloadWithFile,
} from '../../lib'


const handleNotificationCreation = payload => {
  const _payload = {
    ...payload,
    employeeId: getUserId(),
    chainId: undefined,
  }

  return _payload
}

const handlePayload = (resourceName, data) => {
  const CLEAR_MASK = clearMaskRoutes.includes(resourceName)
  const HAS_CHAIN_ID = !chainIdDelete.includes(resourceName)
  const HAS_UPLOAD_FILE = routesWithUpload.includes(resourceName) || 'imagefile' in data

  let payload = data
  const payloadSize = JSON.stringify(payload).length;

  // Check payload size before sending
  if (resourceName === ADVERTISEMENTS && payloadSize > ADVERTISEMENTS_MAX_PAYLOAD_SIZE) {
    throw new Error(`Tamanho máximo de arquivo excedido.`);
  }

  if (resourceName === PRODUCT) {
    payload = {
      ...payload,
      places: payload.places.map(({ placeId, price, defaultStock, partnerPrice, limit, minimumStock, minimumAmount, isGift, discountValue, daysToExpire, rtiCode, productPlaceGiftFuels }) => ({
        placeId, price, defaultStock, minimumStock, minimumAmount, isGift, discountValue, daysToExpire, rtiCode,
        partnerPrice: partnerPrice ? +partnerPrice : null,
        limit: limit ? +limit : null,
        productPlaceGiftFuels,
      })),
      isCash: false,
    }
    delete payload.defaultValue
    delete payload.partnerPrice
  }

  if (CLEAR_MASK) {
    payload = clearMask(payload, ['phone', 'cellphone', 'cnpj'])
  }

  if (HAS_CHAIN_ID) {
    payload = addChainId(payload, resourceName)
  }

  if (HAS_UPLOAD_FILE) {
    payload = makePayloadWithFile(payload, ['logo', 'image', 'imagefile', 'serviceAccount', 'files', 'file', 'productImageFile', 'dotzCertificate'])
  }

  if (resourceName === NOTIFICATION || resourceName === SMS) {
    payload = handleNotificationCreation(payload)
  }

  return payload
}

export default async (resourceName, { data }) => {
  const path = mapPath[resourceName] || resourceName;
  const hasUploadFile = routesWithUpload.includes(path) || 'imagefile' in data
  const URL = `${DEFAULT_API_ADMIN}/${(path || '').replace(
    'chainId',
    getChainId(),
  )}`

  const body = hasUploadFile ? handlePayload(resourceName, data) : JSON.stringify(handlePayload(resourceName, data))
  const { json } = await httpClientReactAdmin(URL, { method: 'POST', body }, !!hasUploadFile)

  return json
}
