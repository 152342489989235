import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Permission from '../Permission';
import { ResponsiveContainer } from 'recharts';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  filterRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '10px 0',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '15px',
    color: '#333',
  },
  card: {
    borderRadius: '12px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'scale(1.005)',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
    },
  },
  noData: {
    color: '#999',
    textAlign: 'center',
    fontSize: '14px',
    margin: '15px 0',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const FilterRow: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.filterRow}>{children}</div>;
};

const DashBoardChild: React.FC<{
  title: string;
  filters?: React.ReactNode;
  content: React.ReactNode;
  aspectRatio: number;
  count?: number;
}> = ({ filters, title, content, aspectRatio, count = 1 }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        {title && (
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        )}
        {filters && <FilterRow>{filters}</FilterRow>}
        {count > 0 ? (
          <ResponsiveContainer aspect={aspectRatio || 1.0 / 1.0}>
            {content}
          </ResponsiveContainer>
        ) : (
          <Typography className={classes.noData}>
            Ainda não há dados processados para essa visualização.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const DashBoardWrapper: React.FC<{
  title: string;
  permission: string;
  filters?: React.ReactNode;
  content: React.ReactNode;
  width?: string;
  link?: string;
  aspectRatio: number;
  count?: number;
}> = ({ filters, permission, title, content, link, aspectRatio, count }) => {
  const classes = useStyles();
  return (
    <Permission permission={permission}>
      {link ? (
        <Link to={link} className={classes.link}>
          <DashBoardChild
            title={title}
            content={content}
            filters={filters}
            aspectRatio={aspectRatio}
            count={count}
          />
        </Link>
      ) : (
        <DashBoardChild
          title={title}
          content={content}
          filters={filters}
          aspectRatio={aspectRatio}
          count={count}
        />
      )}
    </Permission>
  );
};

export default DashBoardWrapper;
