import React, { cloneElement, ReactElement, useState } from 'react';
import { useGetOne, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

interface CheckChainUsesModuleProps {
  children: ReactElement;
  alwaysOn?: boolean;
}

const CheckChainUsesReferral: React.FC<CheckChainUsesModuleProps> = ({ children, ...props }) => {
  const [chainId] = useState(getChainId());
  const { permissions } = usePermissions();
  const isAdmin = permissions && permissions.includes(PERMISSIONS.ADMIN);
  if (!chainId && !isAdmin) { return <></>; }

  const { data: chain } = useGetOne('chains', { id: chainId });


  return ((chain && (chain.refereeValue > 0 || chain.referralValue > 0)) || isAdmin) ? cloneElement(children, props) : null;
}
export default CheckChainUsesReferral;
