import React, { useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar } from 'recharts';
import { formatNumber, getInitialChart } from '../../utils';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';
import { orderBy } from 'lodash';
import { EmployeeType } from '../../models';

const colors = {
    area01: "#82ca9d",
    area02: "#8884d8",
};

export const FillingCompletelyByFuel = () => {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const initialChart = getInitialChart(from, to, { id: 0 });
    const [chartData, setChartData] = useState(initialChart);

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    const formatToPercentage = (value, total) => {
        return (((parseInt(value) * 100) / total) || 0).toFixed(2);
    }

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
            chainId,
            origin,
        };

        if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }

        if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

        dataProvider.getList('dashboard/filling-completely-by-fuel', {
            filter,
            chainId,
        }).then(({ data }) => {
            data.map((element) => {
                element.filling_completely = formatToPercentage(element.filling_completely, element.total);
                element.filling_limit = formatToPercentage(element.filling_limit, element.total);
                return element;
            });

            setChartData(orderBy(data, ['filling_completely'], ['desc']));
        });
    }, [from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            title="Encher tanque por combustíveis"
            permission={PERMISSIONS.FILLINS_CHART}
            aspectRatio={6.0 / 2.5}
            count={chartData.length}
            content={
                <BarChart
                    data={chartData}
                    margin={{
                        top: 20, // Increase the top margin for better spacing
                        bottom: 40, // Adjust bottom margin for axis spacing
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#dfdfdf" />
                    <XAxis dataKey="fuel_name" tick={<RotatedAxisTick fontSize={12} />} interval={0} height={50} />
                    <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} width={40} height={80} />
                    <Tooltip
                        formatter={(value) => `${formatNumber(parseFloat(value) || 0)}% dos abastecimentos`}
                        labelFormatter={(label) => `Combustível: ${label}`}
                        itemStyle={{ color: '#333', fontSize: '14px' }}
                        contentStyle={{ backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px' }}
                    />
                    <Bar dataKey="filling_completely" name="Encheu tanque" fill={colors.area01} barSize={40} />
                    <Bar dataKey="filling_limit" name="Não encheu tanque" fill={colors.area02} barSize={40} />
                </BarChart>
            }
        />
    );
};
