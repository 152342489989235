import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { DashboardWrapper } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getChainId } from '../../lib';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { TableHead } from '@mui/material';

const RANK_ATTENDANT_LIMIT = 10;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 280,
    border: '1px solid #DFE2E6',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tableRow: {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
  headerCell: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#333',
  },
  rankCell: {
    fontWeight: 'bold',
    color: '#4CAF50',
  },
  nameCell: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
  totalCell: {
    color: '#888',
    fontWeight: 'bold',
  },
  topAttendant: {
    backgroundColor: '#ffeb3b', // Yellow for the top 1
  },
  secondAttendant: {
    backgroundColor: '#8bc34a', // Green for second place
  },
  thirdAttendant: {
    backgroundColor: '#03a9f4', // Blue for third place
  },
}));

export const RankAttendants = () => {
  const [attendantsData, setAttendantsData] = useState(null);
  const classes = useStyles();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { chain, from, to, origin, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      limit: RANK_ATTENDANT_LIMIT,
      from: moment.utc(from),
      to: moment.utc(to),
    };

    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (origin) { filter.origin = origin; }

    if (permissions && permissions.includes(PERMISSIONS.RANK_ATTENDANTS)) {
      dataProvider.getList('dashboard/rank-attendants', { filter, chainId })
        .then(response => {
          const attendants = response.data;
          attendants.forEach((attendant, index) => {
            attendant.rank = index + 1;
          });
          setAttendantsData(attendants);
        })
        .catch((e) => { notify('Erro ao carregar o ranking de frentistas', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, stationIds, origin]);

  return (
    <DashboardWrapper
      permission={PERMISSIONS.RANK_ATTENDANTS}
      title="Top Frentistas"
      width="50%"
      content={
        <TableContainer>
          {attendantsData && attendantsData.length > 0 ?
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell} style={{ minWidth: 20 }}>#</TableCell>
                  <TableCell className={classes.headerCell}>Frentista</TableCell>
                  <TableCell className={classes.headerCell} align="right">Abastecimentos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendantsData.map((item, index) => {
                  let rankClass = '';
                  // if (index === 0) rankClass = classes.topAttendant;
                  // else if (index === 1) rankClass = classes.secondAttendant;
                  // else if (index === 2) rankClass = classes.thirdAttendant;

                  return (
                    <TableRow className={`${classes.tableRow} ${rankClass}`} key={item.id}>
                      <TableCell className={classes.rankCell}>{item.rank}</TableCell>
                      <TableCell className={classes.nameCell}>{item.name}</TableCell>
                      <TableCell className={classes.totalCell} align="right">{item.total}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            : <p style={{ textAlign: 'center' }}>Sem dados para exibir</p>}
        </TableContainer>
      }
    />
  );
}
