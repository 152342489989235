import { Grid } from '@mui/material';
import React from "react";
import { Title, usePermissions } from 'react-admin';
import { CheckChainUsesReferral, Permission, Permissions } from "./components";
import { PERMISSIONS } from './constants';
import { DashboardProvider } from "./hooks/useDashboardContext";
import { getChainId, getChainName, getPlaceId } from './lib';
import {
  AditionPointsChart,
  CommentsRating,
  DashboardAsideFilters,
  FillinDuration,
  FillingCompletelyByFuel,
  FillingCompletelyXfillingLimit,
  FillinsAverageTime,
  FillinsByFuelChart,
  FillinsByHour,
  FillinsChart,
  FuelChart,
  GeneratedXRedeemedGraph,
  NewCustomersChart,
  RankAttendants,
  RankCustomersAddedPoints,
  RefereeCount,
} from './resources/dashboard';

const Dashboard = () => {
  const { permissions } = usePermissions();

  if (getChainId() || (permissions && permissions.includes(PERMISSIONS.ADMIN))) {
    return (
      <>
        <Title title={getChainName()} />
        <DashboardProvider>
          <Grid container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={12} lg={2}>
              <Permissions permissions={[PERMISSIONS.FILLINS_AVERAGE_TIME, PERMISSIONS.FILLINS_CHART, PERMISSIONS.GENERATED_REDEEMED_POINTS, PERMISSIONS.FUEL_CHART]}>
                <DashboardAsideFilters />
              </Permissions>
            </Grid>

            <Grid item xs={12} md={10}>
              <Grid container spacing={1.5} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={1.5}>
                    <Permission permission={PERMISSIONS.FILLINS_AVERAGE_TIME}>
                      <Grid item xs>
                        <FillinsAverageTime />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.FILLIN_DURATION}>
                      <Grid item xs>
                        <FillinDuration />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.REFEREE_COUNT}>
                      <CheckChainUsesReferral>
                        <Grid item xs={3}>
                          <RefereeCount />
                        </Grid>
                      </CheckChainUsesReferral>
                    </Permission>
                  </Grid>
                </Grid>

                <Grid item spacing={1.5} xs={12} sm={12} md={12} lg={8}>
                  <Grid container spacing={1.5}>
                    <Permission permission={PERMISSIONS.FILLINS_CHART}>
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <FillinsChart />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.FILLINS_BY_HOUR}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FillinsByHour />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.FUEL_CHART}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FuelChart />
                      </Grid>
                    </Permission>
                    {!getPlaceId() &&
                      <Permission permission={PERMISSIONS.NEW_CUSTOMERS_CHART}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <NewCustomersChart />
                        </Grid>
                      </Permission>
                    }
                    <Permission permission={PERMISSIONS.FILLINS_CHART}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FillingCompletelyXfillingLimit />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.FILLINS_CHART}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FillingCompletelyByFuel />
                      </Grid>
                    </Permission>
                    <Permission permission={PERMISSIONS.WORD_CLOUD}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CommentsRating />
                      </Grid>
                    </Permission>
                  </Grid>
                </Grid>

                <Grid item spacing={1.5} xs={12} sm={12} md={12} lg={4}>
                  <Grid container spacing={1.5}>
                    {!getPlaceId() &&
                      <Permission permission={PERMISSIONS.GENERATED_REDEEMED_POINTS}>
                        <Grid item xs={6} sm={6} md={6} lg={12}>
                          <RankCustomersAddedPoints />
                        </Grid>
                      </Permission>
                    }
                    <Permission permission={PERMISSIONS.FILLINS_CHART}>
                      <Grid item xs={6} sm={6} md={6} lg={12}>
                        <FillinsByFuelChart />
                      </Grid>
                    </Permission>
                    {!getPlaceId() &&
                      <Permission permission={PERMISSIONS.ADITION_POINTS}>
                        <Grid item xs={6} sm={6} md={6} lg={12}>
                          <AditionPointsChart />
                        </Grid>
                      </Permission>
                    }
                    {!getPlaceId() &&
                      <Permission permission={PERMISSIONS.GENERATED_REDEEMED_POINTS}>
                        <Grid item xs={6} sm={6} md={6} lg={12}>
                          <GeneratedXRedeemedGraph />
                        </Grid>
                      </Permission>
                    }
                    {!getPlaceId() &&
                      <Permission permission={PERMISSIONS.RANK_ATTENDANTS}>
                        <Grid item xs={6} sm={6} md={6} lg={12}>
                          <RankAttendants />
                        </Grid>
                      </Permission>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid >
        </DashboardProvider >
      </>
    )
  }
  return <React.Fragment />;
}

export default Dashboard;
