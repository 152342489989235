import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { ArrayField, BooleanField, ChipField, Datagrid, DateField, FormDataConsumer, FunctionField, NumberField, ReferenceArrayField, RichTextField, SelectField, SimpleShowLayout, SingleFieldList, TextField, useRecordContext } from 'react-admin';
import { alterPriceTypes, alterPriceValueTypes, daysOfTheWeek, discountTypes, frequencyChoices } from '../../../constants';
import { FuelDiscountCostValueType } from '../../../models';

const FormSummary: any = ({ formData, chain }) => {
  const classes = useStyles();

  const AmountField = (props) => {
    const record = useRecordContext();
    if (!record || record.uniqueDiscountValue) { return null; }

    return (
      <NumberField
        {...props}
        label="Volume"
        textAlign="center"
        sortable={false}
        source="amount"
        emptyText="0"
        options={{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    )
  }

  return (
    <>
      <SimpleShowLayout record={formData}>
        <FunctionField render={record => record && discountTypes[record.type]} label="Tipo" />
        {
          formData.type === 'special' &&
          <TextField source="description" label="Descrição" />
        }
        {
          formData.selectsAllPlaces ?
            <FunctionField render={() => 'Todos'} label="Postos" />
            :
            <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
        }
        {
          formData.type === 'special' &&
          <ReferenceArrayField label="Grupos de clientes" reference="chains/chainId/customer-groups" source="customerGroupIds" emptyText="Sem grupo">
            <FormDataConsumer>
              {({ formData }: any) => {
                if (!Object.keys(formData).length) { return <Typography variant="body2">Sem grupo</Typography>; }
                return (
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                )
              }}
            </FormDataConsumer>
          </ReferenceArrayField>
        }
        {
          formData.customerIds &&
          <FunctionField label="Clientes selecionados" render={() => `${formData.customerIds.length} clientes`} />
        }
        <TextField source="usageLimit" label="Limite de uso" emptyText="--" />
        <TextField source="intervalInHours" label="Intervalo de uso (horas)" emptyText="--" />
        {
          formData.type === 'special' &&
          <BooleanField source="isSchedule" label="Agendamento ativo" />
        }
        {
          (formData.type === 'special' && formData.isSchedule) &&
          <FunctionField render={record => record && moment(record.startAt).format('DD/MM/YYYY HH:mm')} label="Data inicial" />
        }
        {
          (formData.type === 'special' && formData.isSchedule) &&
          <FunctionField render={record => record && moment(record.endAt).format('DD/MM/YYYY HH:mm')} label="Data final" />
        }
        {
          (formData.type === 'special' && formData.isSchedule) &&
          <FunctionField render={record => record && frequencyChoices.find(dt => dt.id === record.frequency)?.name} label="Frequência" />
        }
        {
          (formData.type === 'special' && formData.isSchedule && formData.frequency === 'weekly') &&
          <ArrayField source="weekSchedual" label="Horários">
            <Datagrid bulkActionButtons={false}>
              <FunctionField render={record => record && daysOfTheWeek.find(wd => wd.id === record.dayOfTheWeek)?.name} label="Dia" />
              <FunctionField render={record => record && record.startTime ? moment(record.startTime).format('HH:mm') : '--'} label="Inicio" />
              <FunctionField render={record => record && record.endTime ? moment(record.endTime).format('HH:mm') : '--'} label="Fim" />
              <BooleanField source="isActive" label="Ativo?" />
            </Datagrid>
          </ArrayField>
        }
        {
          (formData.type === 'special' && formData.isSchedule && formData.frequency === 'daily') &&
          <FunctionField render={record => (record && record.startTime) ? moment(record.startTime).format('HH:mm') : '--'} label="Hora de inicio" />
        }
        {
          (formData.type === 'special' && formData.isSchedule && formData.frequency === 'daily') &&
          <FunctionField render={record => (record && record.endTime) ? moment(record.endTime).format('HH:mm') : '--'} label="Hora de fim" />
        }

        <Box component="div" sx={{ p: 2, border: '1px dashed grey', marginTop: 2 }}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>Descontos por combustível</Typography>
          {
            formData.fuelDiscounts && formData.fuelDiscounts.filter(fd => fd.discounts && fd.discounts.length).map(fuelDiscount => (
              <div>
                <SimpleShowLayout className={classes.containerFuelDiscount} record={fuelDiscount}>
                  <ArrayField source="discounts" label={fuelDiscount.name}>
                    <Datagrid bulkActionButtons={false} style={{ padding: 0 }}>
                      {
                        chain && chain.isPriceIncreaseEnabled &&
                        <FunctionField
                          render={data => data && data.alterPriceType ? alterPriceTypes[data.alterPriceType] : 'Desconto'}
                          label="Tipo"
                        />
                      }
                      {
                        chain && chain.isPriceIncreaseEnabled &&
                        <FunctionField
                          render={data => data && data.alterPriceValueType ? alterPriceValueTypes[data.alterPriceValueType] : 'Centavos'}
                          label="Tipo de valor"
                          textAlign="center"
                        />
                      }
                      <FunctionField
                        label="Valor"
                        textAlign="center"
                        sortable={false}
                        render={data => data && data.alterPriceValueType === 'percentage' ?
                          data.alterPriceValue ? `${data.alterPriceValue}%` : '0%' :
                          <NumberField
                            source="alterPriceValue"
                            emptyText="R$ 0,00"
                            options={{
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }}
                          />
                        }
                      />
                      {/* <AmountField label="Volume" /> */}
                      <SelectField
                        source="costValueType"
                        label="Forma de troca"
                        choices={FuelDiscountCostValueType.fuelDiscountCostValueTypeChoices}
                        textAlign="center"
                        emptyText={FuelDiscountCostValueType.translateType(FuelDiscountCostValueType.points)}
                      />
                      <FunctionField
                        label="Custo"
                        textAlign="center"
                        sortable={false}
                        render={record => (
                          <NumberField
                            source="costValue"
                            textAlign="center"
                            emptyText={record?.costValueType === FuelDiscountCostValueType.dotz ? 'R$0,00' : '0'}
                            options={{
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              ...(record?.costValueType === FuelDiscountCostValueType.dotz ? {
                                style: 'currency',
                                currency: 'BRL',
                              } : {})
                            }}
                          />
                        )}
                      />
                      {
                        formData && formData.type === 'special' &&
                        <NumberField
                          source="daysToExpire"
                          label="Validade em dias"
                          emptyText="--"
                          textAlign="center"
                        />
                      }
                      {/* <BooleanField source="uniqueDiscountValue" label="Valor do desconto único" textAlign="center" /> */}
                    </Datagrid>
                  </ArrayField>
                </SimpleShowLayout>
              </div>
            ))
          }
        </Box>

        {
          formData.hasNotification &&
          <Box component="div" sx={{ p: 2, border: '1px dashed grey', marginTop: 2 }}>
            <Typography variant="body1">Notificação</Typography>
            <SimpleShowLayout className={classes.noPadding} record={formData}>
              <TextField source="notification.title" label="Título" />
              <RichTextField source="notification.body" label="Mensagem" />
              <BooleanField source="notification.pushNotification" label="Push" />
              {/* <BooleanField source="notification.sms" label="sms"/> */}
              {
                formData.notification?.pushNotification &&
                <TextField source="notification.pushMessage" label="Messagem Push" />
              }
              {
                formData.notification?.smsNotification &&
                <TextField source="notification.pushMessage" label="Messagem SMS" />
              }
              <BooleanField source="notification.sendNow" label="Envio agora" />
              {
                !formData.notification?.sendNow &&
                <DateField source="notification.sendAt" label="Envio às" showTime />
              }
            </SimpleShowLayout>
          </Box>
        }
      </SimpleShowLayout>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  containerFuelDiscount: {
    padding: 0,
    paddingTop: '0px !important',
  },
  noPadding: {
    padding: 0,
  }
}));

export default FormSummary;