import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { PERMISSIONS } from '../../constants';
import { getChainId, getEmployeeType, getAttendantId } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import moment from 'moment';
import { EmployeeType } from '../../models';

export const FillinsByHour = () => {
    const [fillinsData, setFillinsData] = useState(null);

    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { from, to, chain, origin, stationIds } = useDashboardContext();

    const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

    useEffect(() => {
        const filter = {
            from: moment.utc(from),
            to: moment.utc(to),
            origin,
        };
        if (stationIds && stationIds.length > 0) {
            filter.placeIds = stationIds;
        }
        if (getEmployeeType() === EmployeeType.attendant) {
            filter.attendantId = getAttendantId();
        }

        if (permissions && permissions.includes(PERMISSIONS.FILLINS_BY_HOUR)) {
            dataProvider.getList('dashboard/fillins-per-hour', {
                filter, chainId,
            })
                .then((response) => {
                    const fillins = response.data;
                    setFillinsData(fillins);
                })
                .catch((e) => {
                    notify('Erro ao carregar abastecimentos por hora.', 'warning', console.log(e));
                });
        }
    }, [permissions, from, to, chain, origin, stationIds]);

    return (
        <DashboardWrapper
            permission={PERMISSIONS.FILLINS_BY_HOUR}
            title="Abastecimentos Por Hora"
            aspectRatio={6.0 / 2.5}
            content={
                <BarChart
                    data={fillinsData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="4 4" stroke="#E0E0E0" />
                    <XAxis
                        dataKey="hour"
                        tick={<RotatedAxisTick fontSize={12} />}
                        interval={0}
                        height={40}
                        tickLine={false}
                        axisLine={{ stroke: '#CCCCCC' }}
                    />
                    <YAxis
                        width={50}
                        domain={['auto', 'auto']}
                        type="number"
                        tickLine={false}
                        axisLine={{ stroke: '#CCCCCC' }}
                    />
                    <Tooltip
                        isAnimationActive={false}
                        contentStyle={{
                            backgroundColor: '#ffffff',
                            border: '1px solid #cccccc',
                            borderRadius: '5px',
                            padding: '10px',
                            fontSize: '14px',
                            color: '#333333',
                            boxShadow: '0 1px 4px rgba(0,0,0,0.3)',
                        }}
                        cursor={{ fill: '#f5f5f5' }}
                    />
                    <Bar
                        dataKey="count"
                        fill="#5AD8A6"
                        barSize={40}
                        radius={[5, 5, 0, 0]}
                        name="Quantidade de Abastecimentos"
                    />
                </BarChart>
            }
        />
    );
};
