import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import { forceTwoDigits } from '../../utils';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Card, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { EmployeeType } from '../../models';

export const FillinsAverageTime = () => {
  const [avg, setAvg] = useState('--:--:--');

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { from, to, chain, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = { from, to };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    dataProvider.getList('dashboard/fillin-average-time', {
      filter, chainId,
    }).then(({ data }) => {
      const { 0: { average } } = data;

      let avg = '--:--:--';
      if (average) {
        // hours must be more than 24
        const totalHour = moment.duration('00:00:00').add(average * 60, 'seconds').asHours();
        const hour = Math.floor(totalHour);
        const minutes = Math.floor((totalHour - hour) * 60);
        const seconds = Math.floor((((totalHour - hour) * 60) - minutes) * 60);

        avg = `${forceTwoDigits(hour)}:${forceTwoDigits(minutes)}:${forceTwoDigits(seconds)}`;
      }

      setAvg(avg);
    }).catch(err => console.error(err));
  }, [from, to, chain, stationIds]);

  return (
    <Permission permission={PERMISSIONS.FILLINS_AVERAGE_TIME}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Link to="/your-link">
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& .icon': {
                color: 'secondary.main',
              },
              '&:before': {
                position: 'absolute',
                top: '50%',
                left: 0,
                display: 'block',
                content: `''`,
                height: '200%',
                aspectRatio: '1',
                transform: 'translate(-30%, -60%)',
                borderRadius: '50%',
                backgroundColor: 'secondary.main',
                opacity: 0.15,
              },
            }}
          >
            <Box width="3em" className="icon">
              <AccessTimeIcon fontSize="large" />
            </Box>
            <Box textAlign="right">
              <Typography color="textSecondary">Um abastecimento a cada</Typography>
              <Typography variant="h5" component="h2">
                {avg}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Card>
    </Permission>
  );
};
