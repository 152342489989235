import React, { useState, useEffect } from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import useDashboardContext from '../../hooks/useDashboardContext';
import Permission from '../../components/Permission';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Card, Typography } from '@mui/material';
import { EmployeeType } from '../../models';

export const RefereeCount = () => {
  const [count, setCount] = useState(0);

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { from, to, chain, stationIds, origin } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = { from, to };
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (origin) { filter.origin = origin; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }
    dataProvider.getList('dashboard/referee', { filter, chainId })
      .then(({ data }) => {
        setCount(data[0].total);
      }).catch(err => console.error(err));
  }, [from, to, chain, stationIds, origin]);

  return (
    <Permission permission={PERMISSIONS.REFEREE_COUNT}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: 'secondary.main',
            },
            '&:before': {
              position: 'absolute',
              top: '50%',
              left: 0,
              display: 'block',
              content: `''`,
              height: '200%',
              aspectRatio: '1',
              transform: 'translate(-30%, -60%)',
              borderRadius: '50%',
              backgroundColor: 'secondary.main',
              opacity: 0.15,
            },
          }}
        >
          <Box width="3em" className="icon">
            <AccountCircleIcon fontSize="large" />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">Clientes Indicados</Typography>
            <Typography variant="h5" component="h2">
              {count}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Permission>
  );
};