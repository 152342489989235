import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ArrayInput, BooleanInput, FormDataConsumer, NumberInput, RadioButtonGroupInput, SelectInput, SimpleFormIterator, TextInput, required } from "react-admin";
import { alterPriceTypes, alterPriceValueTypes } from "../../constants";
import { calcDotz, cleanNumberAndForceDecimal, formatNumber, formatPoints } from "../../utils";
import PriceInput from "../PriceInput";
import { FuelDiscountCostValueType } from '../../models';
import CheckChainUsesModule, { ModuleKey } from '../CheckChainUsesModule';
import { Tooltip } from '../Tooltip';

const validateDotz = (scopedFormData) => {
  return (value) => {
    const [costValueRounded, calculatedValue] = calcDotz(value, scopedFormData.alterPriceValue);
    if (costValueRounded > calculatedValue) {
      return `Valor Dotz máximo para esse desconto: ${formatNumber(calculatedValue, { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    }
    return undefined;
  }
}

export const FuelAccordion = ({ source, name, hideAmount, alterPriceValueType, enableIncrease }) =>
  <Accordion style={{ width: '100%' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{name}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {enableIncrease ?
        <>
          <RadioButtonGroupInput
            source={`${source}.alterPriceType`}
            defaultValue={'discount'}
            fullWidth
            label=""
            sx={{ height: 35 }}
            style={{ width: 150, marginLeft: 10, marginRight: 10 }}
            choices={[
              { id: 'discount', name: 'Desconto' },
              { id: 'increase', name: 'Acréscimo' },
            ]}
          />
          <SelectInput
            style={{ marginLeft: 16, width: 150 }}
            source={`${source}.alterPriceValueType`}
            label="Tipo de valor"
            choices={[
              { id: 'cents', name: 'Centavos' },
              { id: 'percentage', name: 'Porcentagem' },
              { id: 'prefixed', name: 'Prefixado' },
            ]}
            validate={required()}
          />
          {alterPriceValueType ?
            (alterPriceValueType === 'cents' || alterPriceValueType === 'prefixed') ?
              <PriceInput style={{ marginLeft: 15, width: 150 }} source={`${source}.alterPriceValue`} label="Valor"
              /> :
              <NumberInput style={{ marginLeft: 15, width: 150 }} defaultValue={0} source={`${source}.alterPriceValue`} label="Valor" />
            :
            <></>
          }
        </>
        : <TextInput
          style={{ marginLeft: 16, width: 150 }}
          source={`${source}.discount`}
          label="Desconto por litro"
          format={value => !value ? 'R$ 0,00' : `R$ ${Number(value).toFixed(2)}`}
          parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
        />
      }
      {/* {!hideAmount && <TextInput
        style={{ marginLeft: 16, width: 150 }}
        source={`${source}.amount`}
        label="Quantidade"
        format={value => (!value ? 1 : `${Number(value).toFixed(2)}`)}
        parse={value => (!value ? 1 : +value.replace(/[^\d]/g, '') / 100)}
      />} */}
      <TextInput
        style={{ marginLeft: 16, width: 150 }}
        source={`${source}.points`}
        label="Pontos"
        format={value => (!value ? '0,00' : `${formatPoints(value)}`)}
        parse={value => (!value ? 0 : cleanNumberAndForceDecimal(value))}
      />
      {/* <BooleanInput
        style={{ marginLeft: 16, paddingTop: 10 }}
        source={`${source}.uniqueDiscountValue`}
        defaultValue
        label="Valor do desconto único"
      /> */}
    </AccordionDetails>
  </Accordion >;

export const FuelDiscountsAccordion = ({ source, name, hideAmount, alterPriceValueType, enableIncrease, getSource, record }) => (
  <Accordion style={{ width: '100%' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{name}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ArrayInput source={`${source}.discounts`} record={record} label="">
        <SimpleFormIterator
          // disableAdd={!enableMultipleDiscounts && scopedFormData.fuelDiscounts && scopedFormData.fuelDiscounts.length}
          disableReordering
          TransitionProps={{ enter: false, exit: false, addEndListener: () => null }
          }>
          <FormDataConsumer>
            {({ getSource, formData, scopedFormData }) => getSource && (
              <Box display={{ xs: 'block', sm: 'flex' }}>
                {
                  formData && formData.type === 'special' &&
                  <CheckChainUsesModule module={ModuleKey.DOTZ}>
                    <SelectInput
                      style={{ marginLeft: 16, width: 150 }}
                      source={getSource('costValueType')}
                      label="Forma de troca"
                      choices={FuelDiscountCostValueType.fuelDiscountCostValueTypeChoices}
                      defaultValue={FuelDiscountCostValueType.points}
                      validate={required()}
                    />
                  </CheckChainUsesModule>
                }
                {enableIncrease ?
                  <>
                    <RadioButtonGroupInput
                      source={getSource("alterPriceType")}
                      defaultValue={'discount'}
                      fullWidth
                      label=""
                      sx={{ height: 35 }}
                      style={{ width: 150, marginLeft: 10, marginRight: 10 }}
                      choices={Object.entries(alterPriceTypes).map(([id, name]) => ({ id, name }))}
                    />
                    <SelectInput
                      style={{ marginLeft: 16, width: 150 }}
                      source={getSource('alterPriceValueType')}
                      label="Tipo de desconto"
                      choices={Object.entries(alterPriceValueTypes).map(([id, name]) => ({ id, name }))}
                      defaultValue={'cents'}
                      validate={required()}
                    />
                    {
                      (!scopedFormData?.alterPriceValueType || scopedFormData?.alterPriceValueType === 'cents' || scopedFormData?.alterPriceValueType === 'prefixed') ?
                        <PriceInput style={{ marginLeft: 15, width: 150 }} source={getSource('alterPriceValue')} label="Desconto" />
                        :
                        <NumberInput style={{ marginLeft: 15, width: 150 }} defaultValue={0} source={getSource('alterPriceValue')} label="Valor" />
                    }
                  </>
                  :
                  <PriceInput style={{ marginLeft: 15, width: 150 }} source={getSource('alterPriceValue')} label="Desconto por litro" />
                }
                {
                  scopedFormData && scopedFormData.costValueType === FuelDiscountCostValueType.dotz ?
                  <Tooltip placement="top-start" sxContainer={{ alignItems: 'flex-start' }} title="Esse é o valor que a Dotz pagará ao posto pelo desconto quando ele for resgatado pelo cliente.">
                    <PriceInput
                      style={{ marginLeft: 16, width: 150 }}
                      source={getSource("costValue")}
                      label="Valor Dotz"
                      validate={validateDotz(scopedFormData)}
                    />
                  </Tooltip>
                    :
                    <TextInput
                      style={{ marginLeft: 16, width: 150 }}
                      source={getSource("costValue")}
                      label="Pontos"
                      format={value => (!value ? '0,00' : `${formatPoints(value)}`)}
                      parse={value => (!value ? 0 : cleanNumberAndForceDecimal(value))}
                    />
                }

                {/* {scopedFormData && !scopedFormData.uniqueDiscountValue && <TextInput
                  style={{ marginLeft: 16, width: 150 }}
                  source={getSource("amount")}
                  label="Quantidade"
                  defaultValue={1}
                  format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
                  parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
                />} */}
                {
                  (scopedFormData && scopedFormData.costValueType === FuelDiscountCostValueType.dotz && formData?.type === 'special') &&
                  <NumberInput
                    style={{ marginLeft: 16, width: 180 }}
                    source={getSource("daysToExpire")}
                    label="Expira em (dias)"
                    min={1}
                    parse={value => value ? Math.abs(value) : null}
                  />
                }
                {/* <Box flex={2} marginLeft={2} alignSelf="center" mr={{ xs: 0, sm: '0.5em' }}>
                  <BooleanInput
                    source={getSource("uniqueDiscountValue")}
                    defaultValue
                    label="Valor do desconto único"
                  />
                </Box> */}
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </AccordionDetails>
  </Accordion>
);