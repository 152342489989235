import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import ReactWordcloud from 'react-wordcloud';
import { DashboardWrapper, Tooltip } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import { EmployeeType } from '../../models';

import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

export const CommentsRating = () => {
  const WORD_CLOUD_LIMIT = 100;

  const [commentsData, setCommentsData] = useState([]);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const notify = useNotify();

  const { from, to, chain, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      limit: WORD_CLOUD_LIMIT,
      from: moment.utc(from),
      to: moment.utc(to),
    }
    if (stationIds && stationIds.length > 0) { filter.placeIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    if (permissions && permissions.includes(PERMISSIONS.WORD_CLOUD)) {
      dataProvider.getList('dashboard/comments', {
        filter, chainId
      })
        .then(response => {
          const comments = response.data;
          setCommentsData(comments);
        }).catch((err) => {
          notify('Erro ao carregar o Nuvem de Palavras', { type: 'warning' });
        })
    }
  }, [permissions, from, to, chain, stationIds])

  return (
    <DashboardWrapper
      permission={PERMISSIONS.WORD_CLOUD}
      title={
        <Tooltip title="Uma representação visual da frequência que as palavras aparecem nos comentários em avaliações dos abastecimentos.">
          <span>Nuvem de Palavras</span>
        </Tooltip>}
      aspectRatio={commentsData.length && 6.0 / 2.5}
      count={commentsData.length}
      content={
        <ReactWordcloud
          words={commentsData}
          callbacks={{
            onWordClick: (word, _) => {
              window.open(`#/ratings?${new URLSearchParams({ filter: JSON.stringify({ search: word.text }) })}`)
            },
          }}
          options={{
            deterministic: true,
            rotations: 1,
            rotationAngles: [0, 0],
            fontSizes: [12, 60],
            scale: 'sqrt',
            enableTooltip: true,
            colors: ['#03c8ff', '#ff6f61', '#8e44ad', '#e74c3c', '#2ecc71'],
          }}
        />
      }
    />
  );
}
