export enum FuelDiscountCostValueType {
  points = 'points',
  dotz = 'dotz',
}

export namespace FuelDiscountCostValueType {
  export const translateType = (type: string): string => {
    switch (type) {
      case FuelDiscountCostValueType.points:
        return "Pontos";
      case FuelDiscountCostValueType.dotz:
        return "Dotz";
      default:
        return "";
    }
  };

  export const fuelDiscountCostValueTypeChoices = Object.values(FuelDiscountCostValueType)
    .filter(x => typeof x === "string")
    .map((type) => ({
      id: type,
      name: FuelDiscountCostValueType.translateType(type),
    }));
}