import styled from '@emotion/styled';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Grid, Button as MuiButton, Divider as MuiDivider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import QrCode from 'qrcode.react';
import React, { useRef, useState, cloneElement } from 'react';
import {
  BooleanInput,
  Button,
  Edit,
  FileField,
  FileInput,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  LoadingIndicator,
  NumberInput,
  PasswordInput,
  Record,
  SaveButton,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  email,
  maxValue,
  minValue,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from 'react-admin';
import { HexColorInput, InternationalPhoneInput, PhoneInput } from '../../components/Inputs';
import { Permission, PriceInput } from '../../components';
import { PERMISSIONS } from '../../constants';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
  containerIdField: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
  },
  containerLinkField: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
  }
});

const Divider = styled(MuiDivider)({
  width: '100%',
  marginTop: 12,
  marginBottom: 12,
});

export const WrapperDotz = ({ children, ...props }) => {
  const form = useFormContext();
  if (!form.getValues('useDotz')) return <></>;
  return (
    cloneElement(children, props)
  );
};

export default props => {

  const PostEditToolbar: React.FC<ToolbarProps> = props => {
    const [updating, setUpdating] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const { resource, record } = props as { resource: string, record: Record };

    const saveInGash = () => {
      setUpdating(true);
      dataProvider.update(`${resource}/gash`, { id: record.id, data: {}, previousData: record })
        .then(() => notify("Rede Cadastrada com sucesso"))
        .catch((error) => notify(error.message, { type: 'warning' }))
        .finally(() => redirect("list", "/chains"))
    }

    const handleClick = () => {
      setUpdating(true);
      dataProvider.update(resource, {
        id: record.id,
        data: { isActive: !record?.isActive },
        previousData: { ...record }
      })
        .then(() => {
          notify(`Rede ${record?.isActive ? "desativada" : "ativada"} com sucesso`);
        }).catch(() => {
          notify(`Erro ao ${record?.isActive ? "desativar" : "ativar"} rede`, { type: 'warning' });
        }).finally(() => {
          setUpdating(false);
          refresh();
        });
    };

    return (
      <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
        <SaveButton />
        <Button
          color="primary"
          startIcon={<EditIcon />}
          label="Cadastrar Rede no Gash"
          onClick={saveInGash}
          disabled={updating}
        />
        <Button
          startIcon={updating ? <LoadingIndicator /> : record?.isActive ? <RemoveCircleIcon /> : <AddCircleIcon />}
          label={record?.isActive ? "Desativar" : "Ativar"}
          onClick={handleClick}
          disabled={updating}
        />
      </Toolbar>
    );
  };

  const IdField = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    const styles = useStyles();
    const notify = useNotify();

    const handleClipboard = () => {
      if (record.id) {
        navigator.clipboard.writeText(record.id);
        notify("Id copiado para clipboard", { type: "info", autoHideDuration: 1000 });
      }
    }

    return (
      <SimpleShowLayout className={styles.containerIdField}>
        <TextField source="id" label="id" record={record} />
        <MuiButton onClick={handleClipboard} size="small" variant="outlined" sx={{ marginTop: "20px", marginLeft: "10px" }}>
          <ContentCopyIcon sx={{ fontSize: 18 }} />
        </MuiButton>
      </SimpleShowLayout>
    );
  }

  const LinkField = ({ url }: any) => {
    const styles = useStyles();
    const notify = useNotify();

    const handleClipboard = () => {
      if (url) {
        navigator.clipboard.writeText(url);
        notify("Link copiado para clipboard", { type: "info", autoHideDuration: 1000 });
      }
    }

    return (
      <SimpleShowLayout className={styles.containerLinkField}>
        <MuiButton onClick={handleClipboard} size="small" variant="outlined" sx={{ marginTop: "20px", marginRight: "10px" }}>
          <ContentCopyIcon sx={{ fontSize: 18 }} />
        </MuiButton>
        <FunctionField label="Link" render={() => url} />
      </SimpleShowLayout>
    );
  }

  const QRDownloader = () => {
    const record = useRecordContext();
    const svgRef = useRef(null);
    const url = `https://${record.code}.pontuax.com.br/download`;

    const downloadBlob = (blob: Blob, filename: string) => {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const downloadSvg = () => {
      //@ts-ignore
      const content = svgRef.current?.children[0].innerHTML;
      const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 29 29" width="600" height="600">
      ${content.replace(/fill="#ffffff"/g, 'fill="none"')}
      </svg>`;
      const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
      downloadBlob(blob, `qr-code-${record.name}`);
    };

    return (
      <div style={{ textAlign: "center", marginBottom: 8 }}>
        <div ref={svgRef}>
          <QrCode
            renderAs="svg"
            value={url}
            bgColor="#ffffff"
            fgColor="#000000"
            width={256}
            height={256}
          />
        </div>
        <Button color="primary" style={{ marginTop: 8 }} size="medium" label="Baixar SVG" onClick={downloadSvg} variant="outlined" />
        <LinkField url={url} />
      </div>
    );
  }

  return (
    <Edit {...props}
      resource="chains"
      title="Editar rede"
    >
      <TabbedForm toolbar={<PostEditToolbar {...props} />}>
        <FormTab label="Identificação">
          <IdField label="Id" />
          <TextInput source="name" label="Nome da rede/posto" InputProps={{ autoComplete: "do-not-autofill" }} />
          <PhoneInput
            InputProps={{ autoComplete: "do-not-autofill" }}
            source="phone"
            label="Telefone"
          />
          <TextInput source="email" label="Email" InputProps={{ autoComplete: "do-not-autofill" }} />
          <TextInput source="contact" label="Contato" InputProps={{ autoComplete: "do-not-autofill" }} />
          <TextInput source="emailNotice" label="Email para Avisos" validate={email()} inputProps={{ autoComplete: "do-not-autofill" }} />
          <ImageInput source="logo" label="Logo" accept="image/*">
            <ImageField source="src" title="Logo" />
          </ImageInput>
          <FileInput source="serviceAccount" label="Service Account" accept=".json">
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        {
          Permission({
            permission: PERMISSIONS.REGISTER_CHAIN_MODULE,
            children: (
              <FormTab label='Módulos'>
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      QrCode
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="qrcodeValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="useQrcode" label="Habilitar" />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Promoter
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="promoterValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="usePromoter" label="Habilitar" />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Anúncios
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="advertisementValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="useAdvertisement" label="Habilitar" />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Sorteios
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="drawingValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="useDrawing" label="Habilitar" />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Brinde
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="giftValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="useGift" label="Habilitar" />
                  </Grid>
                </Grid>
                <Grid container lg={8} md={12} spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Dotz
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriceInput source="dotzValue" label="Valor" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BooleanInput source="useDotz" label="Habilitar" />
                  </Grid>
                </Grid>
              </FormTab>
            )
          })
        }
        <FormTab label="Aplicativo">
          <Grid container width={{ xs: '100%' }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography>Dados</Typography>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <TextInput style={{ marginRight: 12 }} source="appName" label="Nome Ícone (Padrão)" variant="standard" fullWidth />
                <TextInput style={{ marginRight: 12 }} source="appNameInsideApp" label="Nome Interno" variant="standard" fullWidth />
                <TextInput source="appNameStore" label="Nome na Loja" variant="standard" fullWidth />
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <TextInput style={{ marginRight: 12 }} source="appSlogan" label="Slogan" variant="standard" fullWidth />
                <InternationalPhoneInput source="appPhone" label="Telefone" variant="standard" fullWidth />
              </Box>
              <Typography>Layout</Typography>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <HexColorInput style={{ marginRight: 12 }} source="appColor" label="Cor principal" fullWidth />
                <HexColorInput source="appHeaderLabelColor" label="Cor da fonte na Home" fullWidth />
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <HexColorInput style={{ marginRight: 12 }} source="appSignUpButtonColor" label="Cor do botão de Cadastro" fullWidth />
                <HexColorInput style={{ marginRight: 12 }} source="appSignInButtonColor" label="Cor do botão de Login" fullWidth />
                <NumberInput source="appLogoWidth" label="Largura da logo na tela Inicial" validate={[minValue(0), maxValue(100)]} fullWidth />
              </Box>
              <BooleanInput source="appShowWelcomeLabel" label="Mostrar mensagem Bem Vindo" />
              <Divider />
              <Typography>Sociais</Typography>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <TextInput style={{ marginRight: 12 }} source="appInstagram" label="Instagram" variant="standard" fullWidth />
                <TextInput style={{ marginRight: 12 }} source="appTwitter" label="Twitter" variant="standard" fullWidth />
                <TextInput source="appYoutube" label="Youtube" variant="standard" fullWidth />
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <TextInput style={{ marginRight: 12 }} source="appFacebookPageId" label="Facebook - Id da página" variant="standard" fullWidth />
                <TextInput source="appFacebook" label="Facebook" variant="standard" fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} alignSelf="center">
              <QRDownloader />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Configurações">
          <TextInput source="firebaseProjectId" label="Id do Projeto no Firebase" InputProps={{ autoComplete: "do-not-autofill" }} />
          <TextInput source="googlePackageName" label="Nome do Pacote no Google" InputProps={{ autoComplete: "do-not-autofill" }} />
          <TextInput source="applePackageName" label="Nome do Pacote na Apple" InputProps={{ autoComplete: 'do-not-autofill' }} />
          <TextInput source="appleAppId" label="ID do Aplicativo na Apple" InputProps={{ id: "field1", autoComplete: "cc-exp" }} />
          <BooleanInput label="Habilitar acréscimo" source="isPriceIncreaseEnabled" />
          <BooleanInput label="Habilitar descontos múltiplos" source="isMultipleDiscountsEnabled" />
          <BooleanInput label="Usa Twilio" source="useTwilio" />
          <BooleanInput label="Usa Limites de Pontuação" source="useLimits" />
          <BooleanInput label="Usa código no frentista" source="useAttendantCode" />
          <BooleanInput label="Conferir localização no abastecimento" source="requireLocationOnFillin" />
          <BooleanInput label="Somente encher tanque" source="appOnlyCompletelyFillin" />
          <BooleanInput label="Usa novo serviço de transação" source="useNewTransactionService" />
        </FormTab>
        <WrapperDotz>
          <FormTab label="Dotz">
            <TextInput source="dotzUser" label="Usuário" sx={{ minWidth: 300 }} />
            <PasswordInput source="dotzPassword" label="Senha" inputProps={{ autocomplete: 'current-password' }} sx={{ minWidth: 300 }}/>
            <TextInput
              source="dotzFactor"
              label="Fator de conversão"
              defaultValue={0}
              format={value => (!value ? '0,000' : `${Number(value).toFixed(3)}`)}
              parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 1000)}
            />
          </FormTab>
        </WrapperDotz>
      </TabbedForm>
    </Edit>
  );
}