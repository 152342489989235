export const JWT_TOKEN = 'jwt-token';
export const DEFAULT_API_ADMIN = process.env.REACT_APP_API_ADMIN || 'http://localhost:5000';

export const EXPIRED = 'timeout';
export const USER_KEY = 'user';
export const GUID_REGEX = /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/;
export const MAX_IDS_PER_REQUEST = 150;

export const PERMISSION_TYPE = {
  ADMIN: 'admin',
  PARTNER_MANAGER: 'partner-manager',
  PARTNER_OPERATOR: 'cashier',
  CHAIN_MANAGER: 'chain-manager',
  CHAIN_OPERATOR_MANAGER: 'chain-operator-manager',
  CHAIN_OPERATOR: 'chain-operator',
};

export const PERMISSIONS = {
  ADMIN: 'admin',

  // User
  CREATE_USER: 'create-user',
  UPDATE_USER: 'update-user',
  CREATE_LOCAL_USER: 'create-local-user',
  UPDATE_LOCAL_USER: 'update-local-user',
  MANAGE_ATTENDANT_USER: 'manage-attendant-user',

  // Customer
  LIST_CUSTOMER: 'list-customer',
  CREATE_CUSTOMER: 'create-customer',
  UPDATE_CUSTOMER: 'update-customer',
  ADD_POINTS: 'add-points',
  ADD_POINTS_BY_FUEL: 'add-points-by-fuel',
  EDIT_MAX_POINTS: 'edit-max-points',
  BLOCK_CUSTOMER: 'block-customer',
  REMOVE_ADDED_POINTS: 'remove-added-points',
  CUSTOMER_GROUPS: 'customer-groups',
  CUSTOMER_STATEMENT: 'customer-statement',
  TERMS_OF_USE: 'terms-of-use',
  CUSTOMERS_DELETED: 'customers-deleted',
  DEVICE: 'customer-device',

  // Partner
  CREATE_PARTNER: 'create-partner',
  UPDATE_PARTNER: 'update-partner',
  DELETE_PARTNER: 'delete-partner',

  //Station
  STATION_GROUPS: 'station-groups',
  LIST_STATION: 'list-stations',
  UPDATE_STATION: 'update-station',

  // Station Admin
  REGISTER_STATION_FINANCIAL: 'register-station-financial',
  REGISTER_STATION_PAYMENT: 'register-station-payment',
  REGISTER_STATION_GPS: 'register-station-gps',
  REGISTER_STATION_SYNCHONIZATION: 'register-station-synchronization',
  REGISTER_STATION_IDENTIFICATION: 'register-station-identification',
  REGISTER_STATION_CONCENTRATOR: 'register-station-concentrator',
  REGISTER_STATION_ADDRESS: 'register-station-address',
  REGISTER_STATION_OPENING_HOURS: 'register-station-opening-hours',
  BILLING_ALERT: 'billing-alert',
  EXPIRATION_ALERT: 'expiration-alert',

  // Rede Admin
  REGISTER_CHAIN_MODULE: 'register-chain-module',

  //Attendant
  CREATE_ATTENDANT: 'create-attendant',
  LIST_ATTENDANT: 'list-attendant',
  DELETE_ATTENDANT: 'delete-attendant',
  UPDATE_ATTENDANT: 'update-attendant',

  // Product
  CREATE_PRODUCT: 'create-product',
  UPDATE_PRODUCT: 'update-product',
  DISABLE_PRODUCT: 'disable-product',
  UPDATE_FEATURED_PRODUCTS: 'update-featured-products',
  CREATE_PRODUCT_CATEGORIES: 'create-product-categories',
  FUEL_DISCOUNTS: 'fuel-discounts',
  FILLIN_CASHBACK: 'fillin-cashback',
  GIFT: 'gift',

  // Stock
  PRODUCT_ENTRY: 'product-entry',
  PRODUCT_WRITEOFF: 'product-writeoff',
  STOCK_ADJUSTMENT: 'stock-adjustment',
  STOCK_TRANSFER: 'stock-transfer',
  DELETE_STOCK_TRANSACTION: 'delete-stock-transaction',

  // Contact
  LIST_MESSAGES: 'list-messages',
  SEND_NOTIFICATION: 'send-notification',
  DELETE_NOTIFICATION: 'delete-notification',
  SEND_SMS: 'send-sms',
  LIST_RATINGS: 'list-ratings',
  RATING_POLL_ITEMS: 'rating-poll-items',
  ANSWER_RATING: 'answer-rating',

  // Profile
  CREATE_PROFILE: 'create-profile',
  UPDATE_PROFILE: 'update-profile',
  ADMIN_PROFILE: 'admin-profile',

  // Reports
  ADDED_POINTS_REPORT: 'added-points-report',
  ADVERTISEMENT_VIEWS_REPORT: 'advertisement-views-report',
  APPROVED_TRANSACTIONS_REPORT: 'approved-transactions-report',
  ATTENDANT_RATING_REPORT: 'attendant-rating-report',
  ATTENDANT_REPORT: 'attendant-report',
  BILLINGS_REPORT: 'billings-report',
  BLOCKED_CUSTOMERS_REPORT: 'blocked-customers-report',
  CLIENTS_GENERATED_POINTS_REPORT: 'clients-generated-points-report',
  COUPONS_REPORT: 'coupons-report',
  CUSTOMERS_WITHOUT_DRAWING_REPORT: 'customers-without-drawing-report',
  DEFAULT_POINTS_REPORT: 'default-points-report',
  DETAILED_POINTS_REPORT: 'detailed-points-report',
  DEVICE_CUSTOMER_REPORT: 'device-customer-report',
  DEVICE_MANUFACTURER_REPORT: 'device-manufacturer-report',
  DISCOUNT_REPORT: 'discount-report',
  DRAWING_REPORT: 'drawing-report',
  EXPIRED_POINTS_REPORT: 'expired-points-report',
  FAVORITE_STATION_REPORT: 'favorite-station-report',
  FILLINS_REPORT: 'fillins-report',
  FILLIN_BY_ATTENDANT_REPORT: 'fillin-by-attendant-report',
  FILLIN_BY_CUSTOMER_REPORT: 'fillin-by-customer-report',
  FILLIN_BY_FUEL_REPORT: 'fillin-by-fuel-report',
  FILLIN_BY_STATION_REPORT: 'fillin-by-station-report',
  FIRST_FILLIN_REPORT: 'first-fillin-report',
  FIXED_DISCOUNT_REPORT: 'fixed-discount-report',
  INACTIVE_CUSTOMERS_REPORT: 'inactive-customers-report',
  LIFE_POINTS_REPORT: 'life-points-report',
  LUCKY_NUMBER_REPORT: 'lucky-number-report',
  MINIMUM_STOCK_REPORT: 'minimum-stock-report',
  MONTHLY_EVOLUTION_REPORT: 'monthly-evolution-report',
  NEW_CUSTOMERS_BY_PROMOTERS_REPORT: 'new-customers-by-promoters-report',
  NEW_CUSTOMERS_REPORT: 'new-customers-report',
  NON_VALIDATED_COUPONS_REPORT: 'non-validated-coupons-report',
  NOTIFICATIONS_REPORT: 'notifications-report',
  PARTNERS_REPORT: 'partners-report',
  PARTNER_VALUES_REPORT: 'partner-values-report',
  PAYMENT_BY_PIX_REPORT: 'payment-by-pix-report',
  PENDING_TRANSACTION_REPORT: 'pending-transaction-report',
  POINTS_BALANCE_REPORT: 'points-balance-report',
  POINTS_REPORT: 'points-report',
  PRODUCTS_REPORT: 'products-report',
  PURCHASE_SUGGESTION_REPORT: 'purchase-suggestion-report',
  REAL_PRICE_REPORT: 'real-price-report',
  RECURRING_CUSTOMERS_REPORT: 'recurring-customers-report',
  REDEEMED_COUPONS_REPORT: 'redeemed-coupons-report',
  REDEEMED_PRODUCTS_REPORT: 'redeemed-products-report',
  REDEMPTION_X_POINTS: 'redemption-x-points',
  REFERED_CUSTOMERS_REPORT: 'refered-customers-report',
  REFUNDED_COUPONS_REPORT: 'refunded-coupons-report',
  REGISTERED_DISCOUNTS_REPORT: 'registered-discounts-report',
  REGISTRATION_CUSTOMERS_REPORT: 'registration-customers-report',
  SOLD_PRODUCTS_REPORT: 'sold-products-report',
  STATION_RATING_REPORT: 'station-rating-report',
  STATION_REPORT: 'station-report',
  STOCK_CHANGES_REPORT: 'stock-changes-report',
  STOCK_STATEMENT_REPORT: 'stock-statement-report',
  TOP_CUSTOMERS_REPORT: 'top-customers-report',
  RATING_POLL_ITEMS_REPORT: 'rating-poll-items-report',

  // REPORT ADMIN
  CONCENTRATOR_ERROR_REPORT: 'concentrator-error-report',
  SOCKET_ERROR_REPORT: 'socket-error-report',
  CURRENT_FILLINS_REPORT: 'current-fillins-report',
  EXTERNAL_PARTNERS_REPORT: 'external-partners-report',
  RETAILERS_REPORT: 'retailers-report',

  // Access
  ACCESS_TOTEM: 'access-totem',
  ACCESS_RESGATE: 'access-resgate',

  // Cashier related
  VALIDATE_COUPON: 'validate-coupon',

  // Others
  REFUND_COUPON: 'refund-coupon',
  SETTINGS: 'settings',
  REPRINT_COUPON: 'reprint-coupon',
  COUPONS_CODE: 'coupons-code',
  CREATE_COUPON: 'create-coupon',
  DELETE_COUPON: 'delete-coupon',
  TAG: 'tag',

  //Marketing
  MARKETING_CAMPAIGNS: 'marketing-campaigns',
  UNIFIED_SEND: 'unified-send',
  DISPATCH_MESSAGE: 'dispatch-message',
  ADVERTISEMENT: 'advertisement',
  BULK_SEND: 'bulk-send',

  //Pontuação
  SPECIAL_RULES: 'special-rules',
  RULES_BY_GROUP: 'rules-by-group',
  DEFAULT_POINTS: 'default-points',
  PENDING_POINTS: 'pending-points',
  MANAGE_COMPANY_WITHOUT_POINT: 'manage-company-without-point',
  IMPORT_POINTS: 'import-points',
  POINT_RULES: 'point-rules',
  DEFAULT_POINTS_FUEL: 'default-points-fuel',
  MINIMUM_AMOUNT_RULE: 'minimum-amount-rule',

  // Security
  POTENCIAL_FRAUD_LIST: 'potencial-fraud-list',
  IGNORE_FRAUD_LOG: 'ignore-fraud-log',
  IGNORE_CUSTOMER_FRAUD_LOG: 'ignore-customer-fraud-log',
  RESOLVE_FRAUD_LOG: 'resolve-fraud-log',
  POTENCIAL_FRAUD_RULE: 'potencial-fraud-rule',
  LIST_CUSTOMERS_IGNORED: 'list-customers-ignored',

  //Dashboard
  ADITION_POINTS: 'adition-points',
  RANK_CUSTOMERS_ADDED_POINTS: 'rank-customers-added-points',
  RANK_ATTENDANTS: 'rank-attendants',
  GENERATED_REDEEMED_POINTS: 'generated-redeemed-points',
  FILLINS_AVERAGE_TIME: 'fillins-average-time',
  FILLIN_DURATION: 'fillin-duration',
  FILLINS_CHART: 'fillins-chart',
  FUEL_CHART: 'fuel-chart',
  FILLINS_BY_HOUR: 'fillins-by-hour',
  NEW_CUSTOMERS_CHART: 'new-customers-chart',
  WORD_CLOUD: 'word-cloud',
  REFEREE_COUNT: 'referee-count',

  // register
  CHAIN: 'chain',
  RETAILER: 'retailer',
  EXTERNAL_PARTNER: 'external-partner',
  PERMISSION_GROUP: 'permission-group',
  USER_PERMISSION: 'user-permission',
  PARENT_FUEL: 'parent-fuel',
  FUEL: 'fuel',
  DRAWING_RULE: 'drawing-rule',
  SERVICE: 'service',

  UNREDEEM_COUPON: 'unredeem-coupon',

  //Abastecimento
  LIST_FILLINS: 'list-fillins',

  //Abastecimento Admin
  EDIT_FILLIN: 'edit-fillin',

  //Abastecimento
  LIST_PAYMENTS: 'list-payments',
  VERIFY_PAYMENTS: 'verify-payments',

  //Sales
  LIST_SALES: 'list-sales',

  //drawing
  DRAWING: 'drawing',

  //billing
  BILLING: 'billing',
  BILLING_PAYMENT: 'billing-payment',

  //payments
  MANAGE_EXTERNAL_PAYMENT_METHOD: 'manage-external-payment-method',

  //Promoter
  CREATE_PROMOTER: 'create-promoter',
  LIST_PROMOTER: 'list-promoters',
  DELETE_PROMOTER: 'delete-promoter',
  UPDATE_PROMOTER: 'update-promoter',

  //App
  APP_LABEL: 'app-label',

  //News
  LIST_NEWS: 'list-news',

  //News Admin
  NEWS: 'news',
};

export const CUSTOMERS = 'customers';
export const MANAGER = 'manager';
export const PRODUCT = 'chains/chainId/products';
export const ADVERTISEMENTS = 'advertisements';
export const NOTIFICATION = 'notifications';
export const SMS = 'sms';
export const ADVERTISEMENTS_MAX_PAYLOAD_SIZE = 25 * 1024 * 1024;

export const chainIdDelete = [
  'chains',
  'chains/chainId/products',
  'chains/chainId/partners',
];
export const routesWithUpload = ['chains', 'chains/chainId/products', 'stations', 'chains/chainId/partners', 'chains/chainId/product-categories', 'services', 'billings', 'advertisements', 'retailers', 'gifts'];
export const clearMaskRoutes = ['chains', 'chains/chainId/partners', 'stations', 'customers'];

export const mapPath = {
  _employees: 'employees',
};

export const STATION_ROUTES = {
  LIST: '/#/stations',
  CREATE: '/#/stations-create',
  EDIT: (id: string): string => `/#/stations-edit/${id}`,
};

// dd/MM/yyyy hh:mm
export const DATE_FORMATER = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const MAX_DATE = "9999-12-31";

export const frequencyChoices = [
  { id: 'never', name: 'Não se repete' },
  { id: 'daily', name: 'Diária' },
  { id: 'weekly', name: 'Semanal' },
];

export const daysOfTheWeek = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'].map((d, i) => ({ id: i, name: d }));

export const alterPriceTypes = {
  increase: 'Acréscimo',
  discount: 'Desconto',
}

export const alterPriceValueTypes = {
  cents: 'Centavos',
  percentage: 'Porcentagem',
  prefixed: 'Prefixado',
}

export const discountTypes = {
  default: 'Padrão',
  special: 'Especial',
};

export const minimumValueCashbackToolTip = `Este valor será o limite mínimo para que seja possível que um usuário faça um
resgate de cashback no aplicativo. Deixe zerado para indicar que NÃO HÁ um limite`;
