import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { PERMISSIONS } from '../../constants';
import useDashboardContext from '../../hooks/useDashboardContext';
import { getAttendantId, getChainId, getEmployeeType } from '../../lib';
import { EmployeeType } from '../../models';

const color = "#5AD8A6"; // Consistent color for the bar fill

export const FillinsByFuelChart = () => {
  const [chartData, setChartData] = useState(null);

  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { from, to, chain, origin, stationIds } = useDashboardContext();

  const chainId = permissions && permissions.includes(PERMISSIONS.ADMIN) ? chain : getChainId();

  useEffect(() => {
    const filter = {
      from: moment.utc(from),
      to: moment.utc(to),
      origin,
    };
    if (stationIds && stationIds.length > 0) { filter.stationIds = stationIds; }
    if (getEmployeeType() === EmployeeType.attendant) { filter.attendantId = getAttendantId(); }

    if (permissions && permissions.includes(PERMISSIONS.FILLINS_CHART)) {
      dataProvider.getList('dashboard/fillin-by-fuel', {
        filter, chainId
      }).then(response => {
        const fuels = response.data.sort((a, b) =>
          a.totalFillins < b.totalFillins ? 1 : (a.totalFillins > b.totalFillins ? -1 : 0));

        setChartData(fuels.filter(f => f.id !== '0' && f.totalFillins > 0));
      })
        .catch((e) => { notify('Erro ao carregar gráfico de abastecimentos por combustível', { type: 'warning' }); });
    }
  }, [permissions, from, to, chain, origin, stationIds]);

  return (
    chartData && chartData.length > 0 && (
      <DashboardWrapper
        permission={PERMISSIONS.FILLINS_CHART}
        title='Abastecimentos por Combustível'
        width='45%'
        link='/report-fillin-by-fuel'
        content={
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 20,
              bottom: 50, // Adjusting bottom margin for the X-axis labels
              left: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#dfdfdf" />
            <XAxis dataKey="name" tick={<RotatedAxisTick fontSize={12} />} interval={0} height={60} />
            <YAxis width={40} domain={[0, 'auto']} tickFormatter={(value) => `${value}`} />
            <Tooltip
              formatter={(value) => `${value} abastecimentos`}
              labelFormatter={(label) => `Combustível: ${label}`}
              itemStyle={{ color: '#333', fontSize: '14px' }}
              contentStyle={{
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '5px 10px',
              }}
            />
            <Bar
              dataKey="totalFillins"
              fill={color}
              barSize={40}
              name="Abastecimentos"
            />
          </BarChart>
        }
      />
    )
  );
};
