import React from 'react';
import { BooleanField, Datagrid, DateField, DeleteButton, List, ReferenceArrayField, ReferenceField, TextField, useListContext, useRecordContext } from 'react-admin';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { DATE_FORMATER, PERMISSIONS } from '../../constants';

const UnifiedSendEditButton = (props) => {
    const record = useRecordContext();
    if (!record) { return null; }
    const { resource } = useListContext();

    return record.dispatchMessages && record.dispatchMessages[0].status === 'scheduled' ?
        <DeleteButton record={record} resource={resource} />
        : null;
}

const ListUnifiedSend: React.FC = (props) => {
    return (
        <Permission permission={PERMISSIONS.UNIFIED_SEND}>
            <List
                {...props}
                perPage={25}
                pagination={<ListPagination />}
                exporter={false}
                title="Envios Únicos"
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField source="title" label="Título" />
                    <DateField source="sendAt" label="Data de Envio" options={DATE_FORMATER} />
                    {/* <BooleanField source="status" label="Enviado" format={(status) => status === ''} /> */}
                    <BooleanField source="sms" label="SMS" />
                    <BooleanField source="pushNotification" label="Notificação" />
                    <ReferenceField source="clientCategoryId" reference="client-categories" label="Categoria de clientes" link={false}>
                        <TextField source="label" />
                    </ReferenceField>
                    <ReferenceField source="stationGroupId" reference="chains/chainId/station-groups" label="Grupo de Postos" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="customerGroupId" reference="chains/chainId/customer-groups" label="Grupo de Clientes" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="tagId" reference="chains/chainId/tag" label="Etiqueta" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    {/* <TextField source="cardinality" label="Cardinalidade" /> */}
                    {/* <UnifiedSendEditButton /> */}
                </Datagrid>
            </List>
        </Permission>
    );
};

export default ListUnifiedSend;
